import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import { AsyncState } from '../lib/reducerUtils';

export const GET_LOGIN = 'api/GET_LOGIN'
export const GET_LOGIN_SUCCESS = 'api/GET_LOGIN_SUCCESS'
export const GET_LOGIN_ERROR = 'api/GET_LOGIN_ERROR'
export const POST_LOGOUT = 'api/POST_LOGOUT'
export const POST_LOGOUT_SUCCESS = 'api/POST_LOGOUT_SUCCESS'
export const POST_LOGOUT_ERROR = 'api/POST_LOGOUT_ERROR'
export const POST_FIND_USER_ID = 'api/POST_FIND_USER_ID'
export const POST_FIND_USER_ID_SUCCESS = 'api/POST_FIND_USER_ID_SUCCESS'
export const POST_FIND_USER_ID_ERROR = 'api/POST_FIND_USER_ID_ERROR'
export const GET_OTP = 'api/GET_OTP';
export const GET_OTP_SUCCESS = 'api/GET_OTP_SUCCESS';
export const GET_OTP_ERROR = 'api/GET_OTP_ERROR';
export const GET_RECALLOTP = 'api/GET_RECALLOTP';
export const GET_RECALLOTP_SUCCESS = 'api/GET_RECALLOTP_SUCCESS';
export const GET_RECALLOTP_ERROR = 'api/GET_RECALLOTP_ERROR';

export const PUT_LOCK_LOGIN = 'api/PUT_LOGIN_LOCK'
export const PUT_LOCK_LOGIN_SUCCESS = 'api/PUT_LOGIN_LOCK_SUCCESS'
export const PUT_LOCK_LOGIN_ERROR = 'api/PUT_LOGIN_LOCK_ERROR'

export const otpAsync = createAsyncAction(
  GET_OTP,
  GET_OTP_SUCCESS,
  GET_OTP_ERROR
)<{ userId: string; otpCd: string, isclear: boolean }, AsyncState<any>, string>();

export const recallOTPAsync = createAsyncAction(
  GET_RECALLOTP,
  GET_RECALLOTP_SUCCESS,
  GET_RECALLOTP_ERROR
)<{ userId: string }, void, string>();

export const loginAsync = createAsyncAction(
  GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_ERROR
)<{ username: string; password: string }, AsyncState<any>, string>();
// <TRequestPayload, TSuccessPayload, TFailurePayload>

export const logoutAsync = createAsyncAction(
  POST_LOGOUT,
  POST_LOGOUT_SUCCESS,
  POST_LOGOUT_ERROR
)<any, void, string>();

export const postFindUserIdAsync = createAsyncAction(
  POST_FIND_USER_ID,
  POST_FIND_USER_ID_SUCCESS,
  POST_FIND_USER_ID_ERROR
)<{name: string, email: string}, string | null, AxiosError>();

export const lockLoginAsync = createAsyncAction(
  PUT_LOCK_LOGIN,
  PUT_LOCK_LOGIN_SUCCESS,
  PUT_LOCK_LOGIN_ERROR
)<{ userId: string }, { locktime: Record<string, string> }, string>();

// export const lockLoginAsync = createAsyncAction(  
//   PUT_LOCK_LOGIN,
//   PUT_LOCK_LOGIN_SUCCESS,
//   PUT_LOCK_LOGIN_ERROR
// )<{ userId: string }, { userId: string; lockUntil: string } | null, string>();

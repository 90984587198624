import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { CreateUserJoinReqDto } from '../../types/myPage/CreateUserJoinReqDto';
import { UserDto } from '../../types/UserDto';
import { ErrorResponse, SuccessResponse } from '../../types/Response';
import { PotalCommDto } from '../../types/PotalCommDto';
import { SelectUserCntnLogDto, UserCntnLog } from '../../types/CntnDto';

export const RESET_MYPAGE                 = 'api/RESET_MYPAGE';
export const RESET_MYPAGE_SUCCESS         = 'api/RESET_MYPAGE_SUCCESS';
export const RESET_MYPAGE_ERROR           = 'api/RESET_MYPAGE_ERROR';

export const GET_MYPAGE_USER_LIST         = 'api/GET_MYPAGE_USER_LIST'
export const GET_MYPAGE_USER_LIST_SUCCESS = 'api/GET_MYPAGE_USER_LIST_SUCCESS'
export const GET_MYPAGE_USER_LIST_ERROR   = 'api/GET_MYPAGE_USER_LIST_ERROR'

export const GET_MYPAGE_USER_INVITE         = 'api/GET_MYPAGE_USER_INVITE'
export const GET_MYPAGE_USER_INVITE_SUCCESS = 'api/GET_MYPAGE_USER_INVITE_SUCCESS'
export const GET_MYPAGE_USER_INVITE_ERROR   = 'api/GET_MYPAGE_USER_INVITE_ERROR'

export const POST_MYPAGE_USER_INVITE         = 'api/POST_MYPAGE_USER_INVITE'
export const POST_MYPAGE_USER_INVITE_SUCCESS = 'api/POST_MYPAGE_USER_INVITE_SUCCESS'
export const POST_MYPAGE_USER_INVITE_ERROR   = 'api/POST_MYPAGE_USER_INVITE_ERROR'

export const DELETE_MYPAGE_USER         = 'api/DELETE_MYPAGE_USER'
export const DELETE_MYPAGE_USER_SUCCESS = 'api/DELETE_MYPAGE_USER_SUCCESS'
export const DELETE_MYPAGE_USER_ERROR   = 'api/DELETE_MYPAGE_USER_ERROR'

export const GET_MYPAGE_USER_INFO         = 'api/GET_MYPAGE_USER_INFO'
export const GET_MYPAGE_USER_INFO_SUCCESS = 'api/GET_MYPAGE_USER_INFO_SUCCESS'
export const GET_MYPAGE_USER_INFO_ERROR   = 'api/GET_MYPAGE_USER_INFO_ERROR'

export const PUT_MYPAGE_USER_INFO         = 'api/PUT_MYPAGE_USER_INFO'
export const PUT_MYPAGE_USER_INFO_SUCCESS = 'api/PUT_MYPAGE_USER_INFO_SUCCESS'
export const PUT_MYPAGE_USER_INFO_ERROR   = 'api/PUT_MYPAGE_USER_INFO_ERROR'

export const POST_MYPAGE_PWD_CHK         = 'api/POST_MYPAGE_PWD_CHK'
export const POST_MYPAGE_PWD_CHK_SUCCESS = 'api/POST_MYPAGE_PWD_CHK_SUCCESS'
export const POST_MYPAGE_PWD_CHK_ERROR   = 'api/POST_MYPAGE_PWD_CHK_ERROR'

export const GET_MYPAGE_CONTACT_US_INFO    = 'api/GET_MYPAGE_CONTACT_US_INFO'
export const GET_MYPAGE_CONTACT_US_SUCCESS = 'api/GET_MYPAGE_CONTACT_US_INFO_SUCCESS'
export const GET_MYPAGE_CONTACT_US_ERROR   = 'api/GET_MYPAGE_CONTACT_US_INFO_ERROR'

export const GET_MYPAGE_NEO_PROJECT_CNT         = 'api/GET_MYPAGE_NEO_PROJECT_CNT'
export const GET_MYPAGE_NEO_PROJECT_CNT_SUCCESS = 'api/GET_MYPAGE_NEO_PROJECT_CNT_SUCCESS'
export const GET_MYPAGE_NEO_PROJECT_CNT_ERROR   = 'api/GET_MYPAGE_NEO_PROJECT_CNT_ERROR'

export const GET_MYPAGE_NEO_PROJECT_STATS         = 'api/GET_MYPAGE_NEO_PROJECT_STATS'
export const GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS = 'api/GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS'
export const GET_MYPAGE_NEO_PROJECT_STATS_ERROR   = 'api/GET_MYPAGE_NEO_PROJECT_STATS_ERROR'

export const GET_MYPAGE_NEO_JOBS_STATS         = 'api/GET_MYPAGE_NEO_JOBS_STATS'
export const GET_MYPAGE_NEO_JOBS_STATS_SUCCESS = 'api/GET_MYPAGE_NEO_JOBS_STATS_SUCCESS'
export const GET_MYPAGE_NEO_JOBS_STATS_ERROR   = 'api/GET_MYPAGE_NEO_JOBS_STATS_ERROR'

export const GET_MYPAGE_NEO_USAGES_CURRENT         = 'api/GET_MYPAGE_NEO_USAGES_CURRENT'
export const GET_MYPAGE_NEO_USAGES_CURRENT_SUCCESS = 'api/GET_MYPAGE_NEO_USAGES_CURRENT_SUCCESS'
export const GET_MYPAGE_NEO_USAGES_CURRENT_ERROR   = 'api/GET_MYPAGE_NEO_USAGES_CURRENT_ERROR'

export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT         = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT'
export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS'
export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR   = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR'

export const PUT_USER_ROLE         = 'api/PUT_USER_ROLE'
export const PUT_USER_ROLE_SUCCESS = 'api/PUT_USER_ROLE_SUCCESS'
export const PUT_USER_ROLE_ERROR   = 'api/PUT_USER_ROLE_ERROR'

export const PUT_USER_GROUP_ROLE         = 'api/PUT_USER_GROUP_ROLE'
export const PUT_USER_GROUP_ROLE_SUCCESS = 'api/PUT_USER_GROUP_ROLE_SUCCESS'
export const PUT_USER_GROUP_ROLE_ERROR   = 'api/PUT_USER_GROUP_ROLE_ERROR'

export const GET_INFO_DASH = 'api/GET_INFO_DASH'
export const GET_INFO_DASH_SUCCESS = 'api/GET_INFO_DASH_SUCCESS'
export const GET_INFO_DASH_ERROR = 'api/GET_INFO_DASH_ERROR'

export const USER_CNTN_LIST = 'api/USER_CNTN_LIST';
export const USER_CNTN_LIST_SUCCESS = 'api/USER_CNTN_LIST_SUCCESS';
export const USER_CNTN_LIST_ERROR = 'api/USER_CNTN_LIST_ERROR';

export const resetMyPageDataAsync = createAsyncAction(
  RESET_MYPAGE,
  RESET_MYPAGE_SUCCESS,
  RESET_MYPAGE_ERROR
)<null,null,null>();

export const getMyPageUserListAsync = createAsyncAction(
  GET_MYPAGE_USER_LIST,
  GET_MYPAGE_USER_LIST_SUCCESS,
  GET_MYPAGE_USER_LIST_ERROR
)<SearchSupportDto, any, AxiosError>();

export const findCntnListAsync = createAsyncAction(
  USER_CNTN_LIST,
  USER_CNTN_LIST_SUCCESS,
  USER_CNTN_LIST_ERROR
)<SelectUserCntnLogDto, SuccessResponse<UserCntnLog[], PotalCommDto> | ErrorResponse, AxiosError>();

export const getMyPageUserInviteAsync = createAsyncAction(
  GET_MYPAGE_USER_INVITE,
  GET_MYPAGE_USER_INVITE_SUCCESS,
  GET_MYPAGE_USER_INVITE_ERROR
)<null, any, AxiosError>();

export const postMyPageUserInviteAsync = createAsyncAction(
  POST_MYPAGE_USER_INVITE,
  POST_MYPAGE_USER_INVITE_SUCCESS,
  POST_MYPAGE_USER_INVITE_ERROR
)<CreateUserJoinReqDto, any, AxiosError>();

export const deleteMyPageUserAsync = createAsyncAction(
  DELETE_MYPAGE_USER,
  DELETE_MYPAGE_USER_SUCCESS,
  DELETE_MYPAGE_USER_ERROR
)<string[], any, AxiosError>();

export const getUserInfoAsync = createAsyncAction(
  GET_MYPAGE_USER_INFO,
  GET_MYPAGE_USER_INFO_SUCCESS,
  GET_MYPAGE_USER_INFO_ERROR
)<null, UserDto|null, AxiosError>();

export const putUserInfoAsync = createAsyncAction(
  PUT_MYPAGE_USER_INFO,
  PUT_MYPAGE_USER_INFO_SUCCESS,
  PUT_MYPAGE_USER_INFO_ERROR
)<UserDto, any, AxiosError>();

export const postUserPwdCheckAsync = createAsyncAction(
  POST_MYPAGE_PWD_CHK,
  POST_MYPAGE_PWD_CHK_SUCCESS,
  POST_MYPAGE_PWD_CHK_ERROR
)<UserDto, any, AxiosError>();

export const getMyPageContactUsListAsync = createAsyncAction(
  GET_MYPAGE_CONTACT_US_INFO,
  GET_MYPAGE_CONTACT_US_SUCCESS,
  GET_MYPAGE_CONTACT_US_ERROR
)<SearchSupportDto, any, AxiosError>();

export const getNeospectraProjectCntAsync = createAsyncAction(
  GET_MYPAGE_NEO_PROJECT_CNT,
  GET_MYPAGE_NEO_PROJECT_CNT_SUCCESS,
  GET_MYPAGE_NEO_PROJECT_CNT_ERROR
)<any, any[] | null, AxiosError>();

export const getNeospectraProjectStatsAsync = createAsyncAction(
  GET_MYPAGE_NEO_PROJECT_STATS,
  GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS,
  GET_MYPAGE_NEO_PROJECT_STATS_ERROR
)<any, any, AxiosError>();

export const getNeospectraJobsStatsAsync = createAsyncAction(
  GET_MYPAGE_NEO_JOBS_STATS,
  GET_MYPAGE_NEO_JOBS_STATS_SUCCESS,
  GET_MYPAGE_NEO_JOBS_STATS_ERROR
)<any, any, AxiosError>();

export const getNeospectraUsagesCurrentAsync = createAsyncAction(
  GET_MYPAGE_NEO_USAGES_CURRENT,
  GET_MYPAGE_NEO_USAGES_CURRENT_SUCCESS,
  GET_MYPAGE_NEO_USAGES_CURRENT_ERROR
)<any, any, AxiosError>();

export const getNeospectraUsagesServiesLeftAsync = createAsyncAction(
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT,
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS,
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR
)<any, any[] | null, AxiosError>();

export const putUserRoleAsync = createAsyncAction(
  PUT_USER_ROLE,
  PUT_USER_ROLE_SUCCESS,
  PUT_USER_ROLE_ERROR
)<{userIds: string[], role: string}, SuccessResponse<null> | ErrorResponse, AxiosError>();

export const putUserGroupRoleAsync = createAsyncAction(
  PUT_USER_GROUP_ROLE,
  PUT_USER_GROUP_ROLE_SUCCESS,
  PUT_USER_GROUP_ROLE_ERROR
)<{groupId:string,  sltnId:string,userIds: string[], role: string}, SuccessResponse<null> | ErrorResponse, AxiosError>();

export const getInfoseedMypageAsync = createAsyncAction(
  GET_INFO_DASH,
  GET_INFO_DASH_SUCCESS,
  GET_INFO_DASH_ERROR
)<null, any, AxiosError>();

import './styles/App.scss';
// import Users from './containers/Users';
// import StateTest from './containers/StateTest';
// import StoreTest from './containers/StoreTest';
//import PotalHeader from './containers/PotalHeader';
//import UserDesc from './containers/UserDesc';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import NotFound from './containers/NotFound';
import Header2 from './containers/header';
import FooterContainer from './containers/footer';
import Login from './pages/login/Login';
import ForgotId from './pages/forgot/Id';
import ForgotPassword from './pages/forgot/Password';
import RegistrationForm from './pages/register';
import MypageProjectManage from './pages/mypage/ProjectManage';
import MypageProductManage from './pages/mypage/ProductManage';
import MypageContactUsManage from './pages/mypage/MypageContactUs';
import ProjectList from './pages/project/ProjectList';
import ServiceOverview from './pages/service/ServiceOverview';
import MainFeatures from './pages/service/MainFeatures';
import MainPage from './pages/main';
import Notice from './pages/support/Notice';
import FAQ from './pages/support/FAQ';
import ContactUs from './pages/support/ContactUs';
import DetailView from './pages/support/DetailView';
import ContactUsWrite from './pages/support/ContactUsWrite';
import ContactUsDetail from './pages/support/ContactUsDetail';
import ProductGuide from './pages/product/ProductGuide';
import MypageUserUpdate from './pages/mypage/MypageUserUpdate';
import Privacy from './pages/policy/privacy';
import Term from './pages/policy/term';
import Email from './pages/policy/email';

import { useEffect, useState } from 'react';

import BuySuccess from './pages/mypage/BuySuccess';
import BuyFail from './pages/mypage/BuyFail';
import MypageUserManage from './pages/mypage/MypageUserManage';
import MypageMemberManage from './pages/mypage/MypageMemberManage';

import { getProfile, logout, checkSession, sessionLogout } from './api/authuser';
import SLA from './pages/policy/SLA';
import Reminder from './pages/reminder/Reminder';

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

function AppRoutes() {
  const navigate = useNavigate();

  useEffect(() => {

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'access_token') {
        if (!event.newValue) {
          // 로그아웃 감지
          setIsLoggedIn(false);
          logout(localStorage.getItem("access_token"));
          navigate('/');
        } else {
          // 로그인 감지
          setIsLoggedIn(true);
          getProfile();
        }
      }
    };

    
    const checkUsingSession = async () => {
      const result = await checkSession();
      if(result===''){
        setIsLoggedIn(false);
        sessionLogout(localStorage.getItem("access_token"));
        navigate('/');
      }else{
        if(!isChangePW){
          setIsChangePW(true);
          const modDtStr = localStorage.getItem('modDt');
          if (modDtStr) {
            const modDate = new Date(modDtStr);  
            const now = new Date();              
            const diffTime = now.getTime() - modDate.getTime();
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 

            if (diffDays >= 90) {  
              navigate('/reminder');
            }
          }
        }
      }
    };

    if(localStorage.getItem('sson_sn')!=null&&localStorage.getItem('userId')!=null){
      checkUsingSession();
    }

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    // 현재 탭이 살아있음을 표시
  
    const handleUnload = () => {
      // 일정 시간 후, 다른 탭에서 살아있는 게 없으면 localStorage clear
      const tabs = sessionStorage.getItem('tabAlive');
      if (!tabs) {
        setIsLoggedIn(false);
        sessionLogout(localStorage.getItem("access_token"));
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
      }
    };
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);
  
  
  const [isChangePW, setIsChangePW] = useState(false);
  // 새로고침 했을 때만 실행됨
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    const token = localStorage.getItem('access_token');
    return token !== null; // 토큰이 있으면 true, 없으면 false
  });

  

  return (
    <>
      <Header2 isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div id="contents">
        <Routes>
          <Route path="/" element={<MainPage isLoggedIn={isLoggedIn} />}></Route>
          <Route
            path="/login"
            element={<Login title="로그인" setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/forgotid"
            element={<ForgotId title="아이디 찾기" />}
          />
          <Route
            path="/forgotpassword"
            element={<ForgotPassword title="비밀번호 찾기" />}
          />
          <Route
            path="/register"
            element={<RegistrationForm title="회원가입" />}
          />
          <Route
            path="/mypage/project"
            element={
              <MypageProjectManage
                title="마이페이지"
                pageTitle="프로젝트 관리"
              />
            }
          />
          <Route
            path="/mypage/product"
            element={
              <MypageProductManage
                title="마이페이지"
                pageTitle="제품선택"
              />
            }
          />
          <Route
            path="/mypage/MypageUserManage"
            element={
              <MypageUserManage
                title="마이페이지"
                pageTitle="사용자 관리"
              />
            }
          />
          
          <Route
            path="/mypage/MypageMemberManage"
            element={
              <MypageMemberManage
                title="마이페이지"
                pageTitle="회원 접속 관리"
              />
            }
          />
          <Route
            path="/mypage/MypageUserUpdate"
            element={
              <MypageUserUpdate
                title="마이페이지"
                pageTitle="회원정보수정"
              />
            }
          />

          <Route
            path="/mypage/MypageContactUs"
            element={
              <MypageContactUsManage
                title="마이페이지"
                pageTitle="나의 문의 내역"
              />
            }
          />

          <Route
            path="/overview"
            element={<ServiceOverview title="서비스 개요" />}
          />
          <Route path="/features" element={<MainFeatures title="주요기능" />} />
          <Route path="/product" element={<ProductGuide title="제품안내" />} />
          <Route path="/project" element={<ProjectList title="프로젝트" />}/>

          <Route path="/notice" element={<Notice title="공지사항" />} />
          <Route path="/notice/:id" element={<DetailView title="공지사항" />} />

          <Route path="/faq" element={<FAQ title="FAQ" />} />

          <Route path="/contact" element={<ContactUs title="문의하기" />} />
          <Route path="/contactUsWrite" element={<ContactUsWrite title="문의하기" />} />
          <Route path="/contactUsWrite/:id" element={<ContactUsWrite title="문의하기" />} />
          <Route path="/contactUsDetail/:id" element={<ContactUsDetail title="문의하기" />} />

          <Route path="/buySuccess" element={<BuySuccess title="결제결과" />} />
          <Route path="/buyFail" element={<BuyFail title="결제결과" />} />
          <Route path="/privacy" element={<Privacy title="개인정보처리방침" />} />
          <Route path="/term" element={<Term title="이용약관" />} />
          <Route path="/sla" element={<SLA title="맵프라임클라우드 서비스 수준 협약(SLA : Service Level Agreement)" />} />
          <Route path="/email" element={<Email title="이메일무단수집거부" />} />

          <Route path="/reminder" element={<Reminder title="비밀번호 변경 권장" />} />


          
          {/* <Route path="/test2" element={<StoreTest />}></Route>
        <Route path="/users" element={<Users />}></Route>
        <Route path="/user/:id" element={<UserDesc />}></Route> */}
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
      <FooterContainer isLoggedIn={isLoggedIn} />
    </>
  );
}
export default App;

import {
    ChangeEvent,
    Dispatch,
    FC,
    SetStateAction,
    useState,
    useEffect,
    FormEvent,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Banners from '../../containers/banner';
import Title from '../../components/Titles';
import Buttons from '../../components/Buttons';
import '../../styles/components/input.scss';
import '../../styles/components/icon.scss';
import './login.scss';
import { useNavigate } from 'react-router-dom';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { loginAsync , lockLoginAsync } from '../../modules/authuser';
import TwoStepAuth from '../../containers/TwoStepAuth';
import { getUserInfo } from '../../api/myPage';
import { getUserInfoAsync } from '../../modules/myPage';
import { UserDto } from '../../types/UserDto';

interface PageProps {
    title: string;
    setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
const Login: FC<PageProps> = ({ title, setIsLoggedIn }) => {
    const location = useLocation();
    const from = location.state?.from || "/";
    const [userId, setUserId] = useState('');
    const [userPw, setUserPw] = useState('');
    const [rememberUserId, setRememberUserId] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authuser = useSelector((state: RootState) => state.auth);
    const locktime = useSelector((state: RootState) => state.auth.locktime);

    const [secondAuth, setSecondAuth] = useState<boolean>(false); //2차 인증

    const LOGIN_ATTEMPT_LIMIT = 4;
    //const LOCK_TIME = 15 * 1000; 

    const [attempts, setAttempts] = useState<number>(() => {
        return Number(localStorage.getItem("loginAttempts")) || 0;
    });
    const [lockUntil, setLockUntil] = useState<number>(() => {
        return Number(localStorage.getItem("lockUntil")) || 0;
    });
    
    const [lockRequested, setLockRequested] = useState(false); // lock 요청 여부 추적
    const userInfo  = useSelector((state:RootState) => state.myPage.data);

    const [userDto, setUserDto] = useState<UserDto>({
        userId: ""
        , userName: ""
        , email: ""
        , password: ""
        , userRoleCd: ""
        , ognzId: ""
        , ognzName : ""
    });
        
    // 아이디별로 시도 횟수와 차단 시간을 가져오는 함수
    const getLocalStorageData = (key: string) => {
        return {
            attempts: Number(localStorage.getItem(`${key}_loginAttempts`)) || 0,
            lockUntil: Number(localStorage.getItem(`${key}_lockUntil`)) || 0
        };
    };

    // 아이디별로 로그인 시도 횟수와 차단 시간 업데이트
    const setLocalStorageData = (key: string, attempts: number, lockUntil: number) => {
        localStorage.setItem(`${key}_loginAttempts`, attempts.toString());
        localStorage.setItem(`${key}_lockUntil`, lockUntil.toString());
    };

    // 사용자가 저장한 아이디를 로드
    useEffect(() => {
        const savedUserId = localStorage.getItem('rememberedUserId');
        if (savedUserId) {
            setUserId(savedUserId);
            setRememberUserId(true);
        }
    }, []);

    // 로그인 성공 시 후속 작업 처리
    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            setIsLoggedIn(true);
            localStorage.setItem("userId",userId);
            if (userId) {
                dispatch(getUserInfoAsync.request(null));
            }
                    
            const modDt = userDto.modDt ? new Date(userDto.modDt) : new Date();

            if (modDt < new Date(new Date().setMonth(new Date().getMonth() - 3))) {
                navigate('/reminder');
            } else {
                window.location.href = from; // 이동할 경로
            }
            

            // 로그인 성공 시 시도 횟수 초기화
            setAttempts(0);
            setLockUntil(0);
            localStorage.setItem("loginAttempts", "0");
            localStorage.setItem("lockUntil", "0");
            setLocalStorageData(userId, 0, 0);
            setLockRequested(false);
        }
    }, [localStorage.getItem('access_token'), setIsLoggedIn, navigate]);

    useEffect(() => {
        // console.log('리턴 결과:', JSON.stringify(userInfo, null, 2));
        if (userInfo && userInfo.data) {
            setUserDto(userInfo.data.user);
        }
    },[userInfo]);

    useEffect(() => {
        const { attempts, lockUntil } = getLocalStorageData(userId);
        setAttempts(attempts);
        setLockUntil(lockUntil);
    }, [userId]);

    // 에러가 발생했을 때 처리
    useEffect(() => {
        if (authuser.data.error && secondAuth === false) {
            if (attempts < LOGIN_ATTEMPT_LIMIT) {
                alert("로그인에 실패했습니다. 아이디,비밀번호,잠금상태를 확인해주세요");
    
                const newAttempts = attempts + 1;
                setAttempts(newAttempts);
                setLocalStorageData(userId, newAttempts, lockUntil);
            } 
    
            else if (attempts >= LOGIN_ATTEMPT_LIMIT) { 
                if (!lockRequested) {  // lock 요청을 한 번만 실행
                    dispatch(lockLoginAsync.request({ userId: userId }));
                    setLockRequested(true); 
                }
    
                // locktime을 계속 확인하고 UI 업데이트 (이 부분은 매번 실행)
                const LOCK_TIME = locktime.data?.locktime?.[userId];
                const unlockTime = new Date(LOCK_TIME).getTime() + 5 * 60 * 1000;        

                if(!Number.isNaN(unlockTime)){
                    setLockUntil(unlockTime);
                    alert(
                        `로그인이 잠겼습니다. ${Math.ceil(
                            (unlockTime - Date.now()) / 1000 
                        )}초 후에 다시 시도하세요.`
                    );
                } else {
                    alert('해당 ID는 존재하지 않거나 잠금 상태입니다.')
                }
    
                setLocalStorageData(userId, attempts, unlockTime);
            }
        }
    }, [authuser.data.error]);

    // 잠금시간 5분이 지났는지 확인
    useEffect(() => {
        const interval = setInterval(() => {
            if (lockUntil > 0 && Date.now() > lockUntil) {
                setAttempts(0);
                setLockUntil(0);
                setLocalStorageData(userId, 0, 0);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [lockUntil]);


    // 사용자가 바뀌게 되면 해당 사용자를 위해 attempt 초기화
    useEffect(() => {
        if (authuser?.data?.data?.status === "success") {  
            setAttempts(0);
            setLockUntil(0);
            setLocalStorageData(userId, 0, 0);
            setSecondAuth(true);
        } 
    }, [authuser]);  // authuser가 변경될 때 실행

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'userId') {
            setUserId(event.target.value);
        } else if (event.target.name === 'userPw') {
            setUserPw(event.target.value);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!userId || !userPw) {
            alert('아이디와 비밀번호를 입력하세요.');
            return;
        }
        try {
            const response = await dispatch(loginAsync.request({ username: userId, password: userPw }));
        } catch (error) {
            console.error('로그인 실패:', error);
            alert('로그인에 실패했습니다. 다시 시도해주세요.');
        }
    };

    return (
        <>
            <Banners title={title} />
            <div className="login">
                <div className="row">
                    <div className="container">
                        <Title
                            title="영상정보 관리 및 분석 서비스 로그인"
                            subtitle="영상정보 관리 및 분석 서비스에 오신 것을 환영합니다"
                        />
                        <div className="small-wraps">
                            <form className="login-form" onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend className="sr-only">로그인</legend>
                                    <div className="form-group">
                                        <label htmlFor="userName" className="label">
                                            아이디
                                        </label>
                                        <input
                                            type="text"
                                            id="userName"
                                            name="userId"
                                            className="form-control block"
                                            value={userId}
                                            onChange={handleChange}
                                            placeholder="아이디를 입력해 주세요."
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userPassword" className="label">
                                            비밀번호
                                        </label>
                                        <input
                                            type="password"
                                            id="userPassword"
                                            name="userPw"
                                            className="form-control block"
                                            value={userPw}
                                            onChange={handleChange}
                                            placeholder="영문, 숫자, 특수문자 조합 10~16자리"
                                            required
                                        />
                                    </div>
                                    <div className="authid-save">
                                        <input
                                            type="checkbox"
                                            id="save"
                                            checked={rememberUserId}
                                            onChange={(e) => setRememberUserId(e.target.checked)}
                                        />
                                        <label htmlFor="save">아이디 저장</label>
                                    </div>
                                    <Buttons
                                        text="로그인"
                                        variant="primary"
                                        size="lg"
                                        block
                                        type="submit"
                                        disabled={authuser.data.loading}
                                    />
                                    <div className="login-util">
                                        <Link to={'/forgotid'} className="forgot-link">
                                            아이디 찾기
                                            <i className="icon--arwright-black"></i>
                                        </Link>
                                        <Link to={'/forgotpassword'} className="forgot-link">
                                            비밀번호 찾기
                                            <i className="icon--arwright-black"></i>
                                        </Link>
                                        <Link to={'/register'} className="forgot-link">
                                            회원가입 <i className="icon--arwright-black"></i>
                                        </Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <TwoStepAuth isOpen={secondAuth} setIsOpen={setSecondAuth} user={{ userId, userPw }} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;

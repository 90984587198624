import { FC } from 'react';
import { Link } from "react-router-dom";
import Banners from "../../containers/banner";
import './policy.scss';

interface PageProps {
    title: string;
}

const SLA: FC<PageProps> = ({ title }) => {
    return (
        <div className="">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <div className="cont-agg-all">
                        <section>
                            <div className="agg-item_text">
                                맵프라임클라우드 서비스를 사용하는 고객에게 해당 SLA가 적용되며 SLA는 서비스이용약관의 일부를 구성하고 본 SLA에 규정되어 있지 않는 내용은 서비스이용약관의 기준을 따릅니다.
                            </div>
                        </section>

                        <section>
                            <h3>1. 무료인 경우</h3>
                            <div className="agg-item_text">
                                상품의 이용요금이 책정되어 있지 않거나 유료 상품의 무료 구간에 해당하는 경우, 별도 비용이 부과되지 않는 무료 서비스에는 SLA가 적용되지 않습니다.
                            </div>
                        </section>

                        <section>
                            <h3>2. 서비스 책임</h3>
                            <div className="agg-item_text">
                                맵프라임클라우드 서비스는 해당월 기간 동안 최소 99.7%의 월 가용률을 보장하며, 가용률을 충족하지 못한 경우에는 정해진 기준에 따라 이용요금을 보상합니다.
                            </div>
                            <div className="agg-item_text" style={{ color: 'red' }}>
                                맵프라임클라우드 서비스 상에서 이용 또는 생성된 이용자 데이터에 의해 발생된 모든 권한 및 책임은 맵프라임클라우드 서비스 사용자에게 있으며, 이용자 데이터로 인한 타인 또는 타 서비스와 저작권 분쟁 및 보안 관련 문제 발생 시 모든 책임은 맵프라임클라우드 서비스 이용자에게 있습니다.
                            </div>
                            <div className="agg-item_text">
                                맵프라임클라우드 서비스 및 서비스 상에서 운영되는 모든 데이터는 네이버클라우드 공공SaaS 상에서 운영됩니다. 
                            </div>
                        </section>

                        <section>
                            <h3>3. 장애에 따른 보상 청구 및 지급 절차</h3>
                            <ul className="agg-item_list1">
                                <li data-no="-">장애 : 본 서비스에 대한 모든 연결 요청이 실패함을 말합니다.</li>
                                <li data-no="-">장애 시간 : 해당월 동안 장애가 발생한 시간의 총합을 말하며, 본 서비스를 이용하지 못한 사실을 회원이 회사에 통지한 때 (회원의 통지 전에 회사가 그 사실을 알 경우는 회사가 그 사실을 알게 된 때)부터 측정됩니다. 단, 본 SLA의 적용이 배제되는 경우 그 해당 시간은 장애시간에 포함되지 않습니다.</li>
                                <li data-no="-">월 가용률(%) = 100ⅹ[1- &#123장애 시간의 합(분 단위)/해당월의 총 시간(분 단위)&#125]</li>
                            </ul>
                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">월 가용률</th>
                                            <th scope="col">보상률</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tac">99.0% 이상 ~ 99.7% 미만</td>
                                            <td className="tac">3개월 월 평균 이용요금의 10%</td>
                                        </tr>
                                        <tr>
                                            <td className="tac">95.0% 이상 ~ 99.0% 미만</td>
                                            <td className="tac">3개월 월 평균 이용요금의 20%</td>
                                        </tr>
                                        <tr>
                                            <td className="tac">95.0% 미만</td>
                                            <td className="tac">3개월 월 평균 이용요금의 50%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ul className="agg-item_list1">
                                <li data-no="-">회사가 본 서비스의 월 가용률(%)이 기준치를 충족하지 못하는 것으로 확인하는 경우, 회사는 그 청구 월의 익월 청구 금액에서 보상률에 따른 금액을 차감하여 청구한다.</li>
                            </ul>
                        </section>

                        <section>
                            <h3>4. 사용자 데이터의 유지 관리</h3>
                            <div className="agg-item_text">
                                서비스 사용자가 업로드한 데이터의 유지는 계약의 만료 또는 종료 시 즉시 삭제됩니다.
                            </div>
                        </section>

                        <section>
                            <h3>5. 본 SLA 적용 배제</h3>
                            <div className="agg-item_text">
                                본 SLA 는 아래의 경우에는 적용되지 않는다.
                            </div>
                            <ul className="agg-item_list1">
                                <li data-no="1)">회사의 합리적인 통제를 벗어난 요인으로 인하여 장애가 발생하는 경우(자연 재해, 전쟁, 테러 행위, 소요, 국가적 비상사태, 전국적 네트워크 장애 또는 이에 상응하는 불가항력으로 인해 서비스가 중단된 경우 등)</li>
                                <li data-no="2)">회사 서비스 상에 고객이 설치하여 사용하는 고객 또는 제3자의 장비, 소프트웨어, 애플리케이션 또는 OS에서 장애가 발생하는 경우</li>
                                <li data-no="3)">고객의 허가되지 않은 행동이나 필요한 조치의 불이행(필수 구성 또는 필수 프로그램의 미 설치, 임의 삭제 등)으로 인해 또는 고객의 계정이나 장비를 사용하여 회사 네트워크에 접속하는 고객의 직원, 대리인, 계약자, 공급업체 등 기타 모든 고객측 사람으로 인해 장애가 발생하는 경우</li>
                                <li data-no="4)">고객이 회사의 권고 또는 사용 정책을 따르지 않아 발생하는 장애 또는 고객이 시스템에 대한 적절한 보안 관리를 소홀히 하여 침해나 장애가 발생하는 경우</li>
                                <li data-no="5)">고객의 오입력(존재하지 않는 파일에 대한 액세스 요청 등), 서비스이용약관이나 안내 등에 회사가 명시한 유의사항을 위반하거나 이용한도 또는 용량을 초과하여 서비스를 이용함에 따라 장애가 발생하는 경우</li>
                                <li data-no="6)">회사가 사전 공지한 바에 따라 점검 실행을 위해 서비스를 중단한 경우</li>
                                <li data-no="7)">서비스를 이용하는 고객의 시스템에서 발생한 사고의 확산을 방지하기 위해 서비스가 중단되는 경우</li>
                                <li data-no="8)">회사가 관련 법령에 따른 보호조치를 취하였음에도 외부로부터의 불법적 침해로 인해 서비스 장애가 발생한 경우 </li>
                                <li data-no="9)">회사가 서비스이용약관이나 별도 계약에 따라 고객의 서비스이용 권리를 중지 또는 종료하는 경우</li>
                                <li data-no="10)">고객이 이용요금을 연체하고 있는 서비스 또는 베타테스트, 시험사용, 평가판 등을 이용하는 동안에 장애가 발생하는 경우 </li>
                                <li data-no="11)">기타 회사의 행위(부작위 포함)에 의하지 않은 경우로서 위 사항들에 준하는 경우</li>
                            </ul>
                        </section>

                        <section>
                            <h3>6. 기타 사항</h3>
                            <div className="agg-item_text">
                                사고 또는 장애발생 시 대내외 관련 기관 및 전문가와 협조체계를 구성하여 대응하고, 국가정보원 및 이용기관의 사고 장애 대응 및 예방보안 활동 등에 협조가 필요할 경우 네이버클라우드 및 이용기관과 협의하여 진행합니다.
                            </div>
                            <div className="agg-item_text">
                                클라우드 컴퓨팅 서비스 망 운용 관리에 따른 보안 취약점 개선발굴, 사이버공격 위협에 대한 예방, 대응, 실태평가, 안전성 및 보안대책의 적합성과 이행여부 확인 등의 목적으로 클라우드 사업자 시설에 대한 현장실사 방문, 안전성 보안 측정 실시, 보안진단점검 등 수행 협조 요청 시 네이버클라우드 및 이용기관과 협의하여 진행합니다.
                            </div>
                        </section>

                        <section>
                            <div className="agg-item_text">
                                효력발생일 : 2024년 12월 1일
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SLA;
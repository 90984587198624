import axios from 'axios';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import { CreateContactUsDto } from '../types/support/CreateContactUsDto';
import { UpdateContactUsDto } from '../types/support/UpdateContactUsDto';
import accessTokenPortalApi from './api';
import {PotalCommDto} from '../types/PotalCommDto';
import config from '../config';

interface ApiResponseList {
  status: string;
  data?: any[];
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface ApiResponse {
  status: string;
  data?: any;
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface SuccessResponse<T> {
  status: 'success';
  data: T;
}

interface ErrorResponse {
  status: 'error';
  error: {
    code: string;
    message: string;
  };
}


export async function getNotices(searchNoticeDto: SearchSupportDto): Promise<any | null> {
  try {
    const response = await axios.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/notices`,
      {params : searchNoticeDto }
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function getNotice(searchNoticeDto: SearchSupportDto): Promise<any | null> {
  try {
    const response = await axios.get<ApiResponseList | null>(
      `${config.portalApiUrl}/notices/`+searchNoticeDto.ntcSn,
      {params :  searchNoticeDto}
    );

    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function getFaqs(searchFaqDto: SearchSupportDto): Promise<any | null> {
  try {
    const response = await axios.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/faq`,
      {params : searchFaqDto }
    );

    
    return response.data;
  } catch (error) {
    console.error('Failed to create user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};


export async function getContactUsList(searchSupportDto: SearchSupportDto): Promise<any | null> {
  try {
    if(!searchSupportDto.searchUserId){
      searchSupportDto.searchUserId = localStorage.getItem('userId')?.toString();
    }
    const access_token  = localStorage.getItem('access_token');
    const response = await axios.get<ApiResponseList>(
      `${config.portalApiUrl}/contactUs`,
     
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params :  searchSupportDto,
      }
    );

    return response.data; // 업데이트된 사용자 데이터를 반환
    
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
}

export async function getContactUs(inqSn: number): Promise<any | null> {
  try {
    const access_token  = localStorage.getItem('access_token');
    const response = await axios.get<ApiResponse | null>(
      `${config.portalApiUrl}/contactUs/`+inqSn,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function postContactUs(createContactUsDto: CreateContactUsDto): Promise<any | null> {
  const access_token  = localStorage.getItem('access_token');
  const formData = new FormData();
  if (createContactUsDto.fileList && createContactUsDto.fileList.length > 0) {
    formData.append('fileList', createContactUsDto.fileList[0]); 
  }
  
  // console.log('createContactUsDto 전송 결과:', JSON.stringify(createContactUsDto, null, 2));

  // createNoticetDto의 모든 속성을 FormData에 추가
  Object.entries(createContactUsDto).forEach(([key, value]) => {
    if (key != "fileList") {
      formData.append(key, value); 
    }
  });

  // console.log('formData 전송 결과:', JSON.stringify(formData, null, 2));
  try {
    
    const response = await accessTokenPortalApi.post<CreateContactUsDto>(
      `${config.portalApiUrl}/contactUs`,
      formData,
      {
        headers: { 
          'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 설정
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    // console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error:any) {
    
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '저장시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function putContactUs(updateContactUsDto: UpdateContactUsDto): Promise<any | null> {
  const access_token  = localStorage.getItem('access_token');
  const formData = new FormData();

  // createNoticetDto의 모든 속성을 FormData에 추가
  Object.entries(updateContactUsDto).forEach(([key, value]) => {
    // 파일 리스트인 경우, 배열로 처리
    if (key != "inqSn") {
      if (Array.isArray(value)) {
        value.forEach(file => {
          formData.append(key, file); // 파일 리스트 추가
        });
      } else {
        formData.append(key, value); // 일반 필드 추가
      }
    }
    
  });

    
  try {
    
    const response = await accessTokenPortalApi.put<UpdateContactUsDto>(
      `${config.portalApiUrl}/contactUs/`+updateContactUsDto.inqSn,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 설정
          Authorization: `Bearer ${access_token}`,// multipart/form-data 헤더 설정
        },
      }
    );

    // console.log('수정 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '수정시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function deleteContactUs(inqSn: number): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.delete<any>(
      `${config.portalApiUrl}/contactUs/`+inqSn,
    );

    // console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '삭제시 오류가 발생하였습니다.',
      },
    };
  }
};
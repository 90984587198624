import axios from 'axios';
import { ProfileDto, UserDto } from '../types/UserDto';
import config from '../config';
import { AsyncState } from '../modules/lib/reducerUtils';
import { InfoseedToken, MapprimeToken, Token } from '../modules/authuser';
import accessTokenPortalApi from './api';

export async function checkSession(): Promise<any | null> {
  //console.log('OTP function called');
  try {
    const userId = localStorage.getItem('userId');
    const ssonSn = localStorage.getItem("sson_sn");
    const token = localStorage.getItem('access_token');
    const checkresponse = await axios.post<AsyncState<any>>(
      `${config.portalApiUrl}/auth/check/usesession`,
      {
        userId,  // 본문에 사용자 ID와 세션 정보 포함
        ssonSn
      }
      // ,
      // {
      //   headers: {
      //     'Authorization': `Bearer ${token}`,
      //     'Cache-Control': 'no-cache'  // 캐시 방지 헤더 추가
      //   }
      // }
    );

    if(checkresponse.data.status==='success'){
      return 'success';
    }else{
      return '';
    }

  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

export async function otp(userId: string, otpCd: string, isclear: boolean = false): Promise<AsyncState<Token>| string | null> {
  //console.log('OTP function called');
  try {
    if(!isclear){
      const checkresponse = await axios.post<AsyncState<any>>(
        `${config.portalApiUrl}/auth/check/session`,
        {
          userId,
          otpCd
        } // POST 요청의 본문에 데이터를 포함
      );

      if(checkresponse.data?.error?.code==='EXIST USING USER'){
        return 'EXIST USING USER';
      }
    }

    const response = await axios.post<AsyncState<Token>>(
      `${config.portalApiUrl}/auth/validate/otp`,
      {
        userId,
        otpCd
      } // POST 요청의 본문에 데이터를 포함
    );

    // response.data.token이 존재하면 로그인 성공으로 처리
    if (response.data.data&&isclear) {
      // console.log('토큰 받아오기 성공');
      localStorage.setItem('sson_sn', response.data.data.sson_sn);
      localStorage.setItem('access_token', response.data.data.access_token);
      localStorage.setItem('refresh_token', response.data.data.refresh_token);

      let serverTime = response.data.data.server_time;
      let currentTime = new Date();
      let diffTime = new Date(serverTime).getTime() - currentTime.getTime();

      let accessTokenExpiry = new Date(new Date(response.data.data.access_token_expires_in).getTime() - diffTime);
      localStorage.setItem('access_token_expires_in', accessTokenExpiry.toString());
      let refreshTokenExpiry = new Date(new Date(response.data.data.refresh_token_expires_in).getTime() - diffTime);
      localStorage.setItem('refresh_token_expires_in', refreshTokenExpiry.toString());

      // console.log(`access_token_expires_in: ${response.data.data.access_token_expires_in}`);
      // console.log(`refresh_token_expires_in: ${response.data.data.refresh_token_expires_in}`);

      // console.log(`access_token_expires_in: ${accessTokenExpiry}`);
      // console.log(`refresh_token_expires_in: ${refreshTokenExpiry}`);

      await getProfile();
      if (localStorage.getItem("isMapprimeSubscribe")) {
        await getMapprimeToken();
      }
      if (localStorage.getItem("isInfoseedSubscribe")) {
        await getInfoseedToken();
      }
      sessionStorage.setItem('tabAlive', 'true');
      return response.data;
    }
    else if(response.data.data){
      return 'Not EXIST USING USER';
    } else {
      // console.log('로그인 실패');
      return null;
    }
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}



export async function recallotp(userId: string): Promise<any | null> {
  //console.log('OTP function recalled');
  try {
    const response = await axios.post<any>(
      `${config.portalApiUrl}/user/check/secondauth`,
      {
        userId
      } // POST 요청의 본문에 데이터를 포함
    );

    if (response.data.status === "success") {
      return response.data;
    } else {
      // console.log('로그인 실패');
      return null;
    }
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

export async function login(userId: string, password: string): Promise<any | null> {
  //console.log('Login function called');
  try {
    const response = await axios.post<any>(
      `${config.portalApiUrl}/auth/login`,
      {
        userId,
        password
      } // POST 요청의 본문에 데이터를 포함
    );

    if (response.data.otpdata && response.data.otpdata.status === "success") {
      // // console.log('토큰 받아오기 성공');
      // localStorage.setItem('access_token', response.data.data.access_token);
      // localStorage.setItem('refresh_token', response.data.data.refresh_token);

      // let serverTime = response.data.data.server_time;
      // let currentTime = new Date();
      // let diffTime = new Date(serverTime).getTime() - currentTime.getTime();

      // let accessTokenExpiry = new Date(new Date(response.data.data.access_token_expires_in).getTime() - diffTime);
      // localStorage.setItem('access_token_expires_in', accessTokenExpiry.toString());
      // let refreshTokenExpiry = new Date(new Date(response.data.data.refresh_token_expires_in).getTime() - diffTime);
      // localStorage.setItem('refresh_token_expires_in', refreshTokenExpiry.toString());

      // // console.log(`access_token_expires_in: ${response.data.data.access_token_expires_in}`);
      // // console.log(`refresh_token_expires_in: ${response.data.data.refresh_token_expires_in}`);

      // // console.log(`access_token_expires_in: ${accessTokenExpiry}`);
      // // console.log(`refresh_token_expires_in: ${refreshTokenExpiry}`);

      // await getProfile();
      // if (localStorage.getItem("isMapprimeSubscribe")) {
      //   await getMapprimeToken();
      // }
      // if (localStorage.getItem("isInfoseedSubscribe")) {
      //   await getInfoseedToken();
      // }
      return response.data;
    } else {
      // console.log('로그인 실패');
      return null;
    }
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

export async function logout(access_token: string | null): Promise<null> {
  const keysToRemove = [
    "access_token",
    "access_token_expires_in",
    "refresh_token",
    "refresh_token_expires_in",
    "userName",
    "ognzName",
    "userId",
    "mapprimeToken",
    "userRoleCd",
    "ognzUserGrpSn",
    "@tosspayments/session-id",
    "infoseed_token",
    "isInfoseedSubscribe",
    "isNeospectraSubscribe",
    "isMapprimeSubscribe",
    "@tosspayments/merchant-browser-id",
    "@tosspayments/payment-widget-previous-payment-method-id",
    "modDt",
    "loginAttempts",
    "lockUntil",
    "_lockUntil",
    "_loginAttempts",
    "sson_sn",
  ];

  try {
    // console.log("로그아웃 토큰 값:" + access_token);
    const response = await axios.post<string>(
      `${config.portalApiUrl}/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      }
    );

    // 창 닫기 로직
    const closeWindowIfOpen = (name: string, expectedOrigin: string) => {
      try {
        const targetWindow = window.open("", name); // 이름으로 창 찾기
        if (targetWindow && !targetWindow.closed) {
          // 창이 열려 있는 경우 로그아웃 메시지 전달
          targetWindow.postMessage({ action: "logout" }, expectedOrigin);
          targetWindow.close(); // 창 닫기
        }
      } catch (err) {
        console.warn(`Could not access '${expectedOrigin}':`, err);
      }
    };

    // 각 창 닫기 시도
    closeWindowIfOpen("manager-window", "https://manager.mapprime.co.kr");
    closeWindowIfOpen("neospectra-window", "https://neospectra.kr");
    closeWindowIfOpen("infoseed-window", "https://saas.geonick.name");

    return null;
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  } finally {
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }
}

export async function sessionLogout(access_token: string | null): Promise<null> {
  const keysToRemove = [
    "access_token",
    "access_token_expires_in",
    "refresh_token",
    "refresh_token_expires_in",
    "modDt",
    "userName",
    "ognzName",
    "userId",
    "mapprimeToken",
    "userRoleCd",
    "ognzUserGrpSn",
    "@tosspayments/session-id",
    "infoseed_token",
    "isInfoseedSubscribe",
    "isNeospectraSubscribe",
    "isMapprimeSubscribe",
    "@tosspayments/merchant-browser-id",
    "@tosspayments/payment-widget-previous-payment-method-id",
    "modDt",
    "loginAttempts",
    "lockUntil",
    "_lockUntil",
    "_loginAttempts",
    "sson_sn",
  ];

  try {
    // console.log("로그아웃 토큰 값:" + access_token);
    // const response = await axios.post<string>(
    //   `${config.portalApiUrl}/auth/logout`,
    //   {},
    //   {
    //     headers: {
    //       Authorization: `Bearer ${access_token}`,
    //     }
    //   }
    // );

    // 창 닫기 로직
    const closeWindowIfOpen = (name: string, expectedOrigin: string) => {
      try {
        const targetWindow = window.open("", name); // 이름으로 창 찾기
        if (targetWindow && !targetWindow.closed) {
          // 창이 열려 있는 경우 로그아웃 메시지 전달
          targetWindow.postMessage({ action: "logout" }, expectedOrigin);
          targetWindow.close(); // 창 닫기
        }
      } catch (err) {
        console.warn(`Could not access '${expectedOrigin}':`, err);
      }
    };

    // 각 창 닫기 시도
    closeWindowIfOpen("manager-window", "https://manager.mapprime.co.kr");
    closeWindowIfOpen("neospectra-window", "https://neospectra.kr");
    closeWindowIfOpen("infoseed-window", "https://saas.geonick.name");

    return null;
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    // if (axios.isAxiosError(error)) {
    //   console.error('Axios error:', error.response?.data || error.message);
    // } else {
    //   console.error('Unknown error:', error);
    // }
    
    console.error('Unknown error:', error);
    return null;
  } finally {
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }
}

export async function getProfile(): Promise<AsyncState<ProfileDto> | null> {
  try {
    const response = await accessTokenPortalApi.get<AsyncState<ProfileDto>>(
      `/auth/profile`,
    );

    if (response.data) {
      // console.log('프로필 받아오기 성공');

      // products 배열에서 sltnId가 "GEO_NIC"인 항목을 찾음
      const hasGeoNicProduct = response!.data!.data!.products.some(
        (product) => product.sltnId === "GEO_NIC"
      );

      // products 배열에서 sltnId가 "SIMMETA"인 항목을 찾음
      const hasSimmetaProduct = response!.data!.data!.products.some(
        (product) => product.sltnId === "SIMMETA"
      );

      const hasMapprimeProduct = response!.data!.data!.products.some(
        (product) => product.sltnId === "MAPPRIME"
      );

      // 조건에 따라 전역 구독 상태를 설정
      if (hasGeoNicProduct) {
        localStorage.setItem("isInfoseedSubscribe", "true");
      }

      if (hasSimmetaProduct) {
        localStorage.setItem("isNeospectraSubscribe", "true");
      }

      if (hasMapprimeProduct) {
        localStorage.setItem("isMapprimeSubscribe", "true");
      }

      return response.data;
    } else {
      // console.log('프로필 받아오기 실패');
      return null;
    }
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

// 토큰 리프레시
export async function refreshToken(refreshToken: string): Promise<AsyncState<Token> | null> {
  try {
    const response = await axios.post<AsyncState<Token>>(
      `${config.portalApiUrl}/auth/refresh`,
      { refreshToken }
    );
    if (response.data) {
      // console.log('토큰 갱신 성공');

      return response.data;
    } else {
      // console.log('토큰 갱신 실패');

      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

// 토큰 리프레시
export async function getMapprimeToken(): Promise<MapprimeToken | null> {
  try {
    const response = await axios.post<MapprimeToken>(
      `${config.mapprimeApiUrl}/${config.mapprimeApiVer}/account/signin`,
      {
        t: localStorage.getItem('access_token')
      }
    );
    if (response.data) {
      // console.log('맵프라임 토큰 갱신 성공');
      localStorage.setItem("mapprimeToken", response.data.token_access);
      return response.data;
    } else {
      // console.log('맵프라임 토큰 갱신 실패');
      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

// 토큰 리프레시
export async function getInfoseedToken(): Promise<InfoseedToken | null> {
  try {
    const response = await axios.post<InfoseedToken>(
      `${config.infoseedApiUrl}/${config.mapprimeApiVer}/common/auth`,
      {

      },
      {
        // 요청 헤더에 추가할 데이터
        headers: {
          ptoken: localStorage.getItem('access_token'),
          apikey: config.infoseedApiKey
        }
      }
    );
    if (response.data) {
      // console.log('인포시드 토큰 갱신 성공');
      localStorage.setItem("infoseed_token", response.data.IFTOKEN);
      return response.data;
    } else {
      // console.log('인포시드 토큰 갱신 실패');
      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}
import { FC } from 'react';
import { Link } from "react-router-dom";
import Banners  from "../../containers/banner";
import './policy.scss';

interface PageProps {
    title: string;
}

const Privacy:FC<PageProps> = ({title}) => {
    return (
        <div className="">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <div className="cont-agg-all">
                        <section id="제1조">
                            <h3>제1조 개인정보의 처리 목적</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                ㈜올포랜드 컨소시엄(본 서비스는 ㈜올포랜드, ㈜인포씨드, ㈜네오스펙트라 공동으로 운영한다. 이하 ㈜올포랜드)는 다음과 같은 클라우드서비스 제공을 목적으로 필요에 의한 최소한으로 개인정보를 처리하고 있습니다. 처리하고 있는 개인정보는 클라우드서비스 제공 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                                </li>
                                <li data-no="2">
                                ㈜올포랜드가 개인정보 보호법 제32조에 따라 등록 및 공개하는 개인정보파일의 처리목적은 다음과 같습니다.
                                </li>
                                <div className="table cs-table-line">
                                    <table>
                                        <colgroup>
                                            <col className="width140" />
                                            <col />
                                            <col className="width140" />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col" colSpan={1}>순번</th>
                                                <th scope="col" colSpan={1}>개인정보파일</th>
                                                <th scope="col" colSpan={1}>서비스 운영 기업명</th>
                                                <th scope="col" colSpan={1}>처리목적</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <th scope="row">영상관리서비스 포털 이용자</th>
                                                <th scope="row">㈜올포랜드</th>
                                                <th scope="row">서비스 가입 및 운영</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <th scope="row">영상촬영서비스 이용자</th>
                                                <th scope="row">㈜인포씨드</th>
                                                <th scope="row">영상촬영서비스 이용</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <th scope="row">AI데이터 생성관리서비스 이용자</th>
                                                <th scope="row">㈜네오스펙트라</th>
                                                <th scope="row">AI데이터 생성관리서비스 이용</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">4</th>
                                                <th scope="row">디지털트윈 지도 서비스 이용자</th>
                                                <th scope="row">㈜올포랜드</th>
                                                <th scope="row">디지털트윈 지도 서비스 이용</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ul>
                        </section>

                        <section id="제2조">
                            <h3>제2조 개인정보의 처리 및 보유기간</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                (주)올포랜드에서 처리하는 개인정보는 수집 · 이용 목적으로 명시한 범위 내에서 처리하며, 개인정보보호법 및 관련 법령에서 정하는 보유기간을 준용하여 이행하고 있습니다.
                                </li>
                                <li data-no="2">
                                각각의 개인정보 처리 및 보유기간은 다음과 같습니다.
                                </li>
                                <div className="table cs-table-line">
                                    <table>
                                        <colgroup>
                                            <col className="width140" />
                                            <col />
                                            <col className="width140" />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col" colSpan={1}>순번</th>
                                                <th scope="col" colSpan={1}>개인정보파일</th>
                                                <th scope="col" colSpan={1}>서비스 운영 기업명</th>
                                                <th scope="col" colSpan={1}>운영근거</th>
                                                <th scope="col" colSpan={1}>보유기간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <th scope="row">영상관리서비스 포털 이용자</th>
                                                <th scope="row">㈜올포랜드</th>
                                                <th scope="row">정보주체 동의</th>
                                                <th scope="row">2년</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <th scope="row">영상촬영서비스 이용자</th>
                                                <th scope="row">㈜인포씨드</th>
                                                <th scope="row">정보주체 동의</th>
                                                <th scope="row">2년</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <th scope="row">AI데이터 생성관리서비스 이용자</th>
                                                <th scope="row">㈜네오스펙트라</th>
                                                <th scope="row">정보주체 동의</th>
                                                <th scope="row">2년</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">4</th>
                                                <th scope="row">디지털트윈 지도 서비스 이용자</th>
                                                <th scope="row">㈜올포랜드</th>
                                                <th scope="row">정보주체 동의</th>
                                                <th scope="row">2년</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ul>
                        </section>

                        <section id="제3조">
                            <h3>제3조 처리하는 개인정보 항목</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                (주)올포랜드에서 처리하는 개인정보는 소관업무 및 법령에서 정한 최소한의 개인정보만을 처리합니다.
                                </li>
                                <li data-no="2">
                                개인정보파일의 처리목적‧보유기간 및 항목은 개인정보파일의 특성에 따라 달리 규정하고 있으며, 개인정보파일별 상세한 내용은 다음과 같습니다.
                                </li>
                                <div className="table cs-table-line">
                                    <table>
                                        <colgroup>
                                            <col className="width140" />
                                            <col />
                                            <col className="width140" />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col" colSpan={1}>순번</th>
                                                <th scope="col" colSpan={1}>기업명</th>
                                                <th scope="col" colSpan={1}>기록되는 개인정보 항목</th>
                                                <th scope="col" colSpan={1}>개인정보운영근거</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <th scope="row">㈜올포랜드</th>
                                                <th scope="row">아이디, 비밀번호, 소속기관, 이메일</th>
                                                <th scope="row">정보주체 동의</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <th scope="row">㈜인포씨드</th>
                                                <th scope="row">아이디, 소속기관, 이메일</th>
                                                <th scope="row">정보주체 동의</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <th scope="row">㈜네오스펙트라</th>
                                                <th scope="row">아이디, 소속기관, 이메일</th>
                                                <th scope="row">가정보주체 동의</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            
                            </ul>
                        </section>

                        <section id="제4조">
                            <h3>제4조 개인정보 제3자 제공에 관한 사항</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">㈜올포랜드는 정보주체의 개인정보를 개인정보의 처리 목적에 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</li>
                                <li data-no="2">㈜올포랜드는 영상촬영서비스 또는 AI데이터 생성관리서비스를 신청한 이용자의 개인정보 제3자 제공 동의하에 ㈜인포씨드(영상촬영서비스 제공자) 또는 ㈜네오스펙트라(AI데이터 생성관리서비스 제공자)에게 서비스 제공의 목적에 한하여 제공합니다.</li>
                            </ul>
                        </section>

                        <section id="제5조">
                            <h3>제5조 개인정보 파기 절차 및 방법</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                (주)올포랜드는 원칙적으로 개인정보 처리목적이 달성된 개인정보는 지체없이 파기합니다. 다만, 다른 법령에 따라 보존하여야 하는 경우에는 그러하지 않을 수 있습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
                                    <ul className="agg-item_list2">
                                    <li data-no="가.">
                                        파기절차
                                        <li data-no="1)">
                                        개인정보는 목적 당성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및 기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                                        </li>
                                        <li data-no="2)">
                                        개인정보파일의 파기개인정보파일의 처리 목적 당성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 그 개인정보파일을 파기합니다. 
                                        </li>
                                    </li>
                                    <li data-no="나.">
                                        파기방법
                                        <li data-no="1)">
                                        전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                                        </li>
                                        <li data-no="2)">
                                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                                        </li>
                                    </li>
                                </ul>
                                </li>
                            </ul>
                        </section>

                        
                        <section id="제6조">
                            <h3>제6조 정보주체와 법정대리인의 권리‧의무 및 행사방법</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                정보주체는 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                                <h4>※ 만 14세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이 직접해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.</h4>
                                    <ul className="agg-item_list2">
                                    <li data-no="가.">
                                    개인정보 열람 등 요구 절차
                                    </li>
                                    <img src="/request_procedure.png" alt="개인정보 열람 등 요구 절차" style={{ width: '50%', margin: '20px auto', display: 'block' }} />
                                    <li data-no="나.">
                                    열람 등 요구에 대한 거절 등 조치 결과에 대한 이의 제기 방법·절차
                                        <li data-no="-">
                                        정보주체는 개인정보 열람, 정정·삭제, 처리정지에 대한 조치에 불만이 있거나 이의가 있을 경우에는 이의신청서를 작성하여 개인정보 보호담당자에게 이의제기를 할 수 있습니다.
                                        </li>
                                        <li data-no="-">
                                        ㈜올포랜드는 이의제기를 받은 날로부터 10일 이내에 이의제기 내용을 검토 한 후, 그 결과에 따라 조치하고 개인정보(열람, 정정·삭제, 처리정지) 요구에 대한 결과 통지서에 따라 정보주체에게 안내 드립니다.
                                        </li>
                                    </li>
                                </ul>
                                </li>
                                <li data-no="2">
                                권리행사는 법제처에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며, ㈜올포랜드는 이에 대해 지체없이 조치하겠습니다.
                                </li>
                                <li data-no="3">
                                권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)“ 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                                </li>
                                <li data-no="4">
                                개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                                </li>
                                <li data-no="5">
                                개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                                </li>
                                <li data-no="6">
                                ㈜올포랜드는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                                </li>
                            </ul>
                        </section>

                        <section id="제7조">
                            <h3>제7조 개인정보 안전성 확보 조치</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                    개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                                    <ul className="agg-item_list2">
                                        <li data-no="①">
                                        관리적 조치 : 내부관리계획 수립ㆍ시행, 정기적 직원 교육
                                        </li>
                                        <li data-no="②">
                                        기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 보안프로그램 설치
                                        </li>
                                        <li data-no="③">
                                        물리적 조치 : 전산실 등의 접근 통제
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </section>
                        
                        <section id="제8조">
                            <h3>제8조 개인정보보호 [분야별] 책임관 및 담당자 연락처</h3>
                            
                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col className="width140" />
                                        <col />
                                        <col className="width140" />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={1}>구분</th>
                                            <th scope="col" colSpan={1}>부서명</th>
                                            <th scope="col" colSpan={1}>성명</th>
                                            <th scope="col" colSpan={1}>연락처(이메일)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">개인정보보호</th>
                                            <th scope="row">사원지원그룹</th>
                                            <th scope="row">김은형</th>
                                            <th scope="row">ehkim@all4land.com</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">개인정보보호</th>
                                            <th scope="row">사원지원그룹</th>
                                            <th scope="row">곽은경</th>
                                            <th scope="row">bigkek@all4land.com</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>

                        <section id="제9조">
                            <h3>제9조 개인정보의 열람청구</h3>
                            
                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col className="width140" />
                                        <col />
                                        <col className="width140" />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={1}>구분</th>
                                            <th scope="col" colSpan={1}>부서명</th>
                                            <th scope="col" colSpan={1}>성명</th>
                                            <th scope="col" colSpan={1}>연락처(이메일)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">개인정보보호</th>
                                            <th scope="row">사원지원그룹</th>
                                            <th scope="row">곽은경</th>
                                            <th scope="row">bigkek@all4land.com</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>

                        <section id="제10조">
                            <h3>제10조 정보주체의 권익침해에 대한 구제방법</h3>
                                
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
                                </li>
                                <li data-no="">
                                아래의 기관은 법제처와는 별개의 기관으로서, 법제처의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
                                </li>
                                <li data-no="☞">
                                    개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)
                                </li>
                                <li data-no="☞">
                                    개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                                </li>
                                <li data-no="☞">
                                    대검찰청 사이버수사과 : (국번없이) 1301, privacy@spo.go.kr, (www.spo.go.kr)
                                </li>
                                <li data-no="☞">
                                    경찰청 사이버안전국 : (국번없이) 182, (cyberbureau.police.go.kr)
                                </li>
                                <li data-no="2">
                                개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
                                </li>
                                <li data-no="3">
                                「개인정보 보호법」제35조(개인정보의열람), 제36조(개인정보의 정정ㆍ삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
                                </li>
                                <li data-no="">
                                ※ 중앙행정심판위원회 : (국번없이) 110 (http://www.simpan.go.kr)
                                </li>
                            </ul>
                        </section>

                        <section id="제11조">
                            <h3>제11조 개인정보 처리방침 변경</h3>
                            <ul className="agg-item_list1">
                                이 개인정보처리방침은 2024. 11. 06.부터 적용됩니다.
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { otpAsync, loginAsync, logoutAsync, postFindUserIdAsync, POST_FIND_USER_ID, recallOTPAsync, lockLoginAsync , PUT_LOCK_LOGIN } from './actions';
import { getProfile, login, logout, refreshToken, otp, recallotp } from '../../api/authuser';
import { Token } from './types';
import { ProfileDto, UserDto } from '../../types/UserDto';
import { AsyncState } from '../lib/reducerUtils';
import { findUserId as postFindUserId , LockUser} from '../../api/users';
import createAsyncSaga from '../lib';

const postFindUserIdSaga = createAsyncSaga(postFindUserIdAsync, postFindUserId);

function* loginSaga(action: ReturnType<typeof loginAsync.request>) {
  try {
    const response: AsyncState<any> | null = yield call(
      login,
      action.payload.username,
      action.payload.password
    );
    //console.log(response);
    
    if (response?.status === 'success') {
      
      yield put(loginAsync.success(response));

    } else {
      yield put(loginAsync.failure('Invalid credentials'));
    }
  } catch (error) {
    yield put(loginAsync.failure('An error occurred during login'));
  }
}

function* logoutSaga(action: ReturnType<typeof logoutAsync.request>) {
  try {
    yield call(logout, action.payload.accessToken);
    yield put(logoutAsync.success());
  } catch (error) {
    if (error instanceof Error) {
      yield put(logoutAsync.failure(error.message));
    } else {
      yield put(logoutAsync.failure('로그아웃 중 오류 발생'));
    }
  }
}

// OTP 사가: OTP 요청 처리 및 토큰 저장
function* otpSaga(action: ReturnType<typeof otpAsync.request>) {
  try {
    const response: AsyncState<Token> | null = yield call(otp, action.payload.userId, action.payload.otpCd, action.payload.isclear);
    
    if (response?.data) {
      const profile: AsyncState<ProfileDto> = yield call(getProfile);
      if (profile.data) {
        localStorage.setItem('modDt', profile.data?.user?.modDt?.toString() || '');
        localStorage.setItem('userName', profile.data?.user?.userName);
        localStorage.setItem('userId', profile.data?.user?.userId);
        localStorage.setItem('ognzName', profile.data?.user?.ognzName);
        localStorage.setItem('userRoleCd', profile.data?.user?.userRoleCd.toString());
        
        if (profile.data?.user?.ognzUserGrpSn) {
          localStorage.setItem('ognzUserGrpSn', profile.data?.user?.ognzUserGrpSn.toString());
        }

      }
      yield put(otpAsync.success(response));

    } else {
      yield put(otpAsync.failure('Invalid credentials'));
    }
  } catch (error) {
    let errorMsg = 'OTP 인증 중 오류 발생';
    if (error instanceof Error) {
      errorMsg = error.message;
    }
    yield put(otpAsync.failure(errorMsg));
  }
}

function* recallOTPSaga(action: ReturnType<typeof recallOTPAsync.request>) {
  try {
    yield call(recallotp, action.payload.userId);
    yield put(recallOTPAsync.success());
  } catch (error) {
    let errorMsg = 'OTP 재발급 중 오류 발생';
    if (error instanceof Error) {
      errorMsg = error.message;
    }
    yield put(recallOTPAsync.failure(errorMsg));
  }
}

export function* lockLoginSaga(action: ReturnType<typeof lockLoginAsync.request>) {
  try {
    const lockUntil: string | null = yield call(LockUser, action.payload);

    yield put(
      lockLoginAsync.success({
        locktime: {
          [action.payload.userId]: lockUntil ?? "", // userId를 키로, lockUntil을 값으로 설정
        },
      })
    );
  } catch (error) {
    let errorMsg = "로그인 5회 잠금 중 오류 발생";
    if (error instanceof Error) {
      errorMsg = error.message;
    }
    yield put(lockLoginAsync.failure(errorMsg));
  }
}

export function* authSaga() {
  yield takeEvery(recallOTPAsync.request, recallOTPSaga);
  yield takeEvery(otpAsync.request, otpSaga);
  yield takeEvery(loginAsync.request, loginSaga);
  yield takeEvery(logoutAsync.request, logoutSaga);
  yield takeEvery(POST_FIND_USER_ID, postFindUserIdSaga);
  yield takeEvery(PUT_LOCK_LOGIN, lockLoginSaga);
}
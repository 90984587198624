import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import Dialog from '../components/Dialog';
import { loginAsync, otpAsync, recallOTPAsync } from '../modules/authuser';
import { otp } from '../api/authuser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../modules';
import { useNavigate } from 'react-router-dom';

interface TwoStepAuthProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  user: { userId: string; userPw: string };
}
const TwoStepAuth: FC<TwoStepAuthProps> = ({ isOpen, setIsOpen, user }) => {
  const dispatch = useDispatch();

  const twoStepAuthTitle = '이메일 인증';
  const [isUsing, setUsing] = useState(false);
  const [authCode, setAuthCode] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [resendText, setResendText] = useState<string>('재전송');
  const authuser = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const AUTH_ATTEMPT_LIMIT = 4;

  const [attempts, setAttempts] = useState<number>(() => {
    return Number(localStorage.getItem("emailAuthAttempts")) || 0;
  });

  // 아이디별로 시도 횟수와 차단 시간을 가져오는 함수
  const getLocalStorageData = (key: string) => {
      return {
        attempts: Number(localStorage.getItem(`${key}_emailAuthAttempts`)) || 0,
      };
  };

  // 아이디별로 이메일 시도 횟수와 차단 시간 업데이트
  const setLocalStorageData = (key: string, attempts: number) => {
    localStorage.setItem(`${key}_emailAuthAttempts`, attempts.toString());
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthCode(Number(e.target.value));
  };

  const onClose = () => {
    setIsOpen(false);
    setAuthCode(0);
    setTimeLeft(0);
  };

  // Timer logic
  useEffect(() => {
    if (timeLeft > 0) {
      if (timeLeft == 60) setResendText('1:00');
      else {
        setResendText(`0:${String(timeLeft).padStart(2, '0')}`);
      }
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setResendText('재전송');
    }
  }, [timeLeft]);

  useEffect(() => {

    if (authuser?.data?.status === "success") {  
      const { attempts } = getLocalStorageData(user.userId);
      setAttempts(attempts);
      setLocalStorageData(user.userId, 0);
      return;
    }
  }, [authuser]);  // authuser가 변경될 때 실행

  useEffect(() => {
    if (isUsing) {
      const handleOtpRequest = async () => {
        const userConfirmed = window.confirm("현재 사용 중인 계정입니다. 접속 하시겠습니까?");
        
        if (userConfirmed) {
          await dispatch(
            otpAsync.request({ userId: user.userId, otpCd: authCode.toString(), isclear: true })
          );
        }else{
          window.location.reload();
        }
      };
  
      handleOtpRequest();
    }
  }, [isUsing]);

  // Function to start/restart timer when clicking "Resend"
  const handleResend = () => {
    const response = dispatch(
      recallOTPAsync.request({ userId: user.userId }),
    );
    if (timeLeft === 0) {
      setTimeLeft(60); // Start 60-second countdown
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!authCode) {
      alert('발송된 인증 코드를 입력하세요.');
      return;
    }
    if (attempts >= AUTH_ATTEMPT_LIMIT) {
      alert('인증 코드를 5회 이상 틀리셨습니다. 인증코드를 다시 발송하였습니다.');
      setAttempts(0);
      const response = await dispatch(
        recallOTPAsync.request({ userId: user.userId }),
      );
      return;
    }
    const userId = user.userId;
    const otpCd = authCode.toString();
    const isclear = false;
    var result = await otp(userId, otpCd, isclear);

    if(result==='EXIST USING USER'){
      setUsing(true);
    }
    else if(result === null){
      if (authCode != 0) {
        alert('이메일 인증에 실패했습니다. 다시 시도해 주세요.');
        const newAttempts = attempts + 1;
        setAttempts(newAttempts);
        setLocalStorageData(user.userId, newAttempts);
      }
    }else{
      await dispatch(
        otpAsync.request({ userId: user.userId, otpCd: authCode.toString(), isclear: true })
      );
    }
  };

  return (
    <Dialog
      id="two-step-auth"
      isOpen={isOpen}
      onClose={onClose}
      title={twoStepAuthTitle}
    >
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="regi-input">
            <input
              id="authCode"
              className="form-control block"
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
              }}
              type="number"
              value={authCode === 0 ? '' : authCode}
              onChange={onChangeHandler}
              maxLength={6}
              placeholder="가입한 이메일로 발송된 코드 6자리를 입력해주세요."
              required
            />
            <button
              type="button"
              className={`button button--sm button--outline-black ${
                timeLeft > 0 ? 'disabled' : ''
              }`}
              onClick={handleResend}
              disabled={timeLeft > 0} // Disable button while timer is running
            >
              {resendText}
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="button-group center">
            <button className="button button--sm button--primary" type="submit" disabled={authuser.data.loading}>
              인증번호 확인
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default TwoStepAuth;

import {
    FC,
    useState,
} from 'react';
import Banners from '../../containers/banner';
import Title from '../../components/Titles';
import '../../styles/components/input.scss';
import '../../styles/components/icon.scss';
import './reminder.scss';
import { useNavigate } from 'react-router-dom';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { putUserInfoAsync } from '../../modules/myPage';
import { UserDto } from '../../types/UserDto';

interface PageProps {
    title: string;
}
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
const Reminder: FC<PageProps> = ({ title }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userDto, setUserDto] = useState<UserDto>({
             userId: ""
           , userName: ""
           , email: ""
           , password: ""
           , userRoleCd: ""
           , ognzId: ""
           , ognzName : ""
    });

    const UpdateModifyDate = () => {
        setUserDto(prev => ({ ...prev, modDt: new Date() }));
        dispatch(putUserInfoAsync.request(userDto));
        navigate('/'); // 페이지 이동

    }

    const handleNavigate = () => {
        navigate('/mypage/MypageUserUpdate'); // 페이지 이동
    };

    return (
        <>
            <Banners title={title} />
            <div className="login">
                <div className="row">
                    <div className="container">
                        <Title
                            title="비밀번호 변경 안내"
                            subtitle="비밀번호를 현재 3개월 이상 같은 비밀번호로 사용하시고 있습니다."
                        />

                        {/* 버튼들을 감싸는 div 추가 */}
                        <div className="login-util center">
                            <button onClick={handleNavigate} className="button button--sm button--outline-black">
                                변경하기
                            </button>
                            <button type="button" className="button button--sm button--outline-black" onClick={UpdateModifyDate}>
                                다음에 하기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reminder;

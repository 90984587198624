import axios from "axios";
import { SltnDto } from "../types/SltnDto";
import config from "../config";

interface ApiResponseList {
    status: string;
    data?: any[];
    meta?: SltnDto
    error?: {
        code: string;
        message: string;
    };
}

interface SuccessResponse<T> {
    status: 'success';
    data: T;
}

interface ErrorResponse {
    status: 'error';
    error: {
        code: string;
        message: string;
    };
}

// 아이디 중복 체크
export async function postCheckSltnId(sltnId: string): Promise<SuccessResponse<null> | ErrorResponse> {
    try {
        const response = await axios.post<SuccessResponse<null> | ErrorResponse>(
            `${config.portalApiUrl}/sltn/check-sltnid`,
            { id: sltnId }
        );
        return response.data;
    } catch (error) {
        console.error('Failed to check SltnId:', error);
        return {
            status: 'error',
            error: {
                code: 'INTERNAL_SERVER_ERROR',
                message: 'Failed to check SltnId availability',
            },
        };
    }
}

// 솔루션 리스트 조회
export async function getSltnList(sltnDto: SltnDto): Promise<SuccessResponse<any> | ErrorResponse> {
    try {
        const response = await axios.get<Promise<SuccessResponse<any>> | ErrorResponse>(
            `${config.portalApiUrl}/sltn/list`,
            { params: sltnDto }
        );

        //console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

        return response.data;
    } catch (error) {
        console.error('Failed to getSltnList:', error);
        return {
            status: 'error',
            error: {
                code: 'INTERNAL_SERVER_ERROR',
                message: 'Failed to getSltnList',
            },
        };
    }
};

// 특정 솔루션 정보 조회
export async function getSltnById(sltnId: string): Promise<SuccessResponse<SltnDto> | ErrorResponse> {
    try {
        const response = await axios.get<SuccessResponse<SltnDto> | ErrorResponse>(
            `${config.portalApiUrl}/sltn/${sltnId}`
        );
        return response.data;
    } catch (error) {
        console.error('Failed to get Sltn:', error);
        return {
            status: 'error',
            error: {
                code: 'INTERNAL_SERVER_ERROR',
                message: 'Failed to retrieve solution',
            },
        };
    }
}

// 솔루션 생성
export async function createSltn(createSltnDto: SltnDto): Promise<SuccessResponse<SltnDto> | ErrorResponse> {
    try {
        const response = await axios.post<SuccessResponse<SltnDto> | ErrorResponse>(
            `${config.portalApiUrl}/sltn`, 
            createSltnDto
        );
        return response.data;
    } catch (error) {
        console.error('Failed to create Sltn:', error);
        return {
            status: 'error',
            error: {
                code: 'INTERNAL_SERVER_ERROR',
                message: 'Failed to create solution',
            },
        };
    }
}

// 솔루션 정보 업데이트
export async function updateSltn({sltnId, updateSltnDto}: {sltnId: string, updateSltnDto: SltnDto}): Promise<SuccessResponse<SltnDto> | ErrorResponse> {
    try {
        const response = await axios.put<SuccessResponse<SltnDto> | ErrorResponse>(
            `${config.portalApiUrl}/sltn/${sltnId}`, 
            updateSltnDto
        );
        return response.data;
    } catch (error) {
        console.error('Failed to update Sltn:', error);
        return {
            status: 'error',
            error: {
                code: 'INTERNAL_SERVER_ERROR',
                message: 'Failed to update solution',
            },
        };
    }
}

// 솔루션 삭제
export async function deleteSltn(sltnId: string): Promise<SuccessResponse<null> | ErrorResponse> {
    try {
        const response = await axios.delete<SuccessResponse<null> | ErrorResponse>(
            `${config.portalApiUrl}/sltn/${sltnId}`
        );
        return response.data;
    } catch (error) {
        console.error('Failed to delete Sltn:', error);
        return {
            status: 'error',
            error: {
                code: 'INTERNAL_SERVER_ERROR',
                message: 'Failed to delete solution',
            },
        };
    }
}
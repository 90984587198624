import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { Link } from 'react-router-dom';
import SearchForm   from "../../components/Search";
import SortDisplay   from "../../components/SortDisplay";
import Pagination from '../../components/paging';
import '../../styles/components/icon.scss';
import './board-table.scss';
import './board-view.scss';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { getNoticesAsync } from '../../modules/support';
import { PotalCommDto } from '../../types/PotalCommDto';

const List:FC = () => {
    const [pageingDto, setPageing] = useState<PotalCommDto>({
        currentPageNo : 1
      , pageUnit : 10
      , pageSize : 10
      , firstIndex : 0
      , totalCnt   : 0
      , firstPageNo : 0
      , lastPageNo : 0
      , prevPageNo : 0
      , nextPageNo : 0
      , listTotalCnt : 0
    });
    const dispatch = useDispatch();
    const supportInfo  = useSelector((state:RootState) => state.support.data);
    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy : "REG_DT"
      , currentPageNo : 1
      , pageUnit : 10
    });


    let listTotalCnt = 0;
    let totalCnt = 0;
    let pageUnit = 10;
    let currentPageNo = 1;

    if (supportInfo.data ) {
        if (supportInfo.data.meta) {
            totalCnt = Number(supportInfo.data.meta.totalCnt);
            pageUnit = supportInfo.data.meta.pageUnit;
            currentPageNo = supportInfo.data.meta.currentPageNo;
        }
        
        if (supportInfo.data.data) {
           listTotalCnt = Number(supportInfo.data.data.length);
        }

        // console.log('리턴 결과:', JSON.stringify(supportInfo.data, null, 2));
    }

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getNoticesAsync.request(searchParams));
        };

        fetchData();
        if (supportInfo.data) {
            
            if (supportInfo.data.meta) {
                setPageing({ 
                    currentPageNo : supportInfo.data.meta.currentPageNo
                  , pageUnit : supportInfo.data.meta.pageUnit
                  , pageSize : supportInfo.data.meta.pageSize
                  , firstIndex : supportInfo.data.meta.firstIndex
                  , totalCnt   : supportInfo.data.meta.totalCnt
                  , firstPageNo : supportInfo.data.meta.firstPageNo
                  , lastPageNo : supportInfo.data.meta.lastPageNo
                  , prevPageNo : supportInfo.data.meta.prevPageNo
                  , nextPageNo : supportInfo.data.meta.nextPageNo
                  , listTotalCnt : supportInfo.data.data.length
              });
            }
            
        }
        
        
      },[searchParams,dispatch]);

    const searchHandleSearch = async (newSearchParams: SearchSupportDto) => {  
        await setFormData({...searchParams, searchType : newSearchParams.searchType , searchKeyword : newSearchParams.searchKeyword , currentPageNo: 1 });
    };

    const orderHandleSearch = async (newSearchParams: SearchSupportDto) => {  
        await setFormData({...searchParams, searchOrderBy : newSearchParams.searchOrderBy , pageUnit : newSearchParams.pageUnit , currentPageNo: 1 });
    }

    const onSearch = async (pageNo:number) => {  
        await setFormData({...searchParams , currentPageNo: pageNo });
    }

    return (
        <div className="board-list">
            <div className="row">
                <div className="container">
                    <SearchForm onSearch={searchHandleSearch} groupId="NTC_TYPE_CD"  />
                    {/* search */}

                    <SortDisplay total={totalCnt} currentTotal={listTotalCnt} defSearchParams={searchParams} onSearch={orderHandleSearch} />

                    {/* board-controls */}
                    <ul className='board-table'>
                        <li className="thead">
                            <span className='num md-hidden'>번호</span>
                            <span className='type md-hidden'>구분</span>
                            <span className='subject'>제목</span>
                            <span className='date'>등록일</span>
                            <span className='files md-hidden'>첨부파일</span>
                        </li>
                           {supportInfo.loading ? (
                              <li className="empty"> loading...</li>
                            ) : supportInfo.data && (supportInfo.data?.data ?? []).length > 0 ? ( 
                            supportInfo.data.data && supportInfo.data.data.map((notice: any, idx: number) => 
                            <li className="tbody" key={idx}>
                               <span className='num md-hidden'>{totalCnt - (pageUnit * (currentPageNo -1) + idx)}</span>

                               <span className='type md-hidden'>{notice.ntcTypeNm}</span> 
                               <span className='subject'>
                                  <Link to={'/notice/' + notice.ntcSn}>{notice.ntcTtl}</Link>
                               </span>
                               <span className='date'>{notice.regDt}</span>
                               <span className="files md-hidden">

                               {notice.ntcAtfl && notice.ntcAtfl.length > 0 ? (
                                  <i className={'icon--'+ notice.ntcAtfl[0].atflOrgnlNmExt}></i> 
                               ) : (
                                <span></span>
                               ) }
                               </span>
                            </li>)
                            ) : (<li className="empty"> 등록 된 글이 없습니다. </li>)
                         }
                       </ul>
                       { supportInfo.data && supportInfo.data.meta  ? ( 
                    <Pagination key="1" pageingDto={supportInfo.data.meta} onClick={onSearch} />        
                       ) : (<span></span>) }
                </div>
            </div>
            
        </div>
    )
}

export default List;
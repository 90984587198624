import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import MypageLayout from "./MypageLayout";
import "./MypageMemberManage.scss";

import { PotalCommDto } from '../../types/PotalCommDto';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { findCntnListAsync} from '../../modules/myPage';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/paging';

interface PageProps {
    title: string;
    pageTitle: string;
}

const MypageMemberManage: FC<PageProps> = ({ title, pageTitle}) => {
    const navigate = useNavigate();

    /** 현재 페이지 검색, 페이징 관련 state */
    const [pagingDto, setPagingDto] = useState<PotalCommDto>({
        currentPageNo : 1
        , pageUnit : 10 
        , pageSize : 10 
        , firstIndex : 0
        , totalCnt   : 0
        , firstPageNo : 0
        , lastPageNo : 0
        , prevPageNo : 0
        , nextPageNo : 0
        , listTotalCnt : 0
    });
    const dispatch = useDispatch();

    /** 데이터 목록 */
    const memberList = useSelector((state: RootState) => state.myPage.data);
    
    /** request 필요한 param state */
    const [searchParams, setSearchParams] = useState<SearchSupportDto>({
        searchType : "user_id",
        searchKeyword : "",
        currentPageNo : 1,
        pageUnit : 10, 
        searchOrderBy : "",
        searchOrderByCol: "",
    });

    const totalCnt = Number(memberList.data?.meta?.totalCnt) || 0;
    const listTotalCnt = Number(memberList.data?.data?.length) || 0;

    useEffect(() => {
        const userRoleCd = localStorage.getItem('userRoleCd');
    
        if (userRoleCd && userRoleCd != "ADMIN") {
            alert("화면에 접근 권한이 없습니다.");
            navigate("/");
        }

        handleSearch(1);
    }, []);

    // 검색 process : UI Component 조작 (ex) handleSelectChange) 
    // -> pagingDto update -> searchParams update -> search request
    useEffect(() => {
        setSearchParams((prev) => ({ ...prev, pageUnit: pagingDto.pageUnit }));
    }, [ pagingDto ])

    useEffect(() => {
        handleSearch(1);    
    }, [ searchParams ])

    /** state update 하고 / update된 state로 새롭게 데이터 목록 request */
    const handleSearch = async (pageNo: number) => {
        const ognzUserGrpSn = localStorage.getItem('ognzUserGrpSn');

		if (!ognzUserGrpSn) {
			navigate('/login', { state: { from: `/mypage/MypageUserManage`}});
		}

        const fetchData = async () => {
            try {
                const updatedParams: SearchSupportDto = { 
                    ...searchParams, 
                    currentPageNo: pageNo
                };

                // updatedParams 유효성 검사 (예시)
                if (!updatedParams || !updatedParams.currentPageNo) {
                    throw new Error(`${updatedParams.currentPageNo}번 목록 ${updatedParams} 유효하지 않은 updateParams`);
                }

                dispatch(findCntnListAsync.request(updatedParams)); // 최신 상태 사용
            } catch (error) {
                console.error('데이터 요청 실패:', error);
            }
        };

        await fetchData();
    };


    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        const parsedValue = name === "pageUnit" ? parseInt(value) : value;  // 숫자로 변환

        setPagingDto((prev) => ({ ...prev, [name]: parsedValue }));
    };

    const handleSelectTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setSearchParams((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearchParams((prev) => ({ ...prev, [name]: value }));
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch(1);
        }
    };

    return (
        <MypageLayout bannerTitle={title} pageTitle={pageTitle}>
            <div className="board-list">
                <div className="row">
                    <div className="container">
                        
                        <div className="form-search">
                                <fieldset>
                                    <legend className="sr-only">프로젝트 검색</legend>
                                    <div className="search-input">
                                        <select name="searchType" id="searchType" 
                                        className="form-select" 
                                        title="등급 선택" 
                                        onChange={handleSelectTypeChange} defaultValue={searchParams.searchType}>
                                            <option value="user_id">아이디</option>
                                            <option value="user_ip">IP주소</option>
                                        </select>
                                        <input type="text" id="searchKeyword" name="searchKeyword" className="form-control" title="검색어 입력" placeholder="검색어를 입력하세요" onChange={handleInputKeywordChange} onKeyUp={handleKeyUp} />
                                        <button type="submit" onClick={() => handleSearch(1)} className="button button--primary button--sm  ">검색</button>
                                    </div>
                                </fieldset>
                        </div>

                        <div className="board-controls user-controls">
                            <span className="board-display"><span>총 게시물</span> <em>({listTotalCnt}건</em>/{totalCnt}건)</span>
                            <div className="board-sort">
                                <div>
                                    <select id="pageUnit" name="pageUnit" className="form-select" title="게시판 목록 수 선택" onChange={handleSelectChange}>
                                        <option value="10">10개씩 보기</option>
                                        <option value="20">20개씩 보기</option> 
                                        <option value="30">30개씩 보기</option>
                                        <option value="40">40개씩 보기</option>
                                        <option value="50">50개씩 보기</option>
                                    </select>
                                </div>
                                <div>
                                </div>
                            </div>
                            
                        </div>
                        
                        {/* board-controls */}
                        <ul className='board-table user-tbl'>
                            <li className="thead">
                                <span className='text-center'>번호</span>
                                <span className='text-center'>사용자 아이디</span>
                                <span className='text-center'>IP</span>
                                <span className='text-center'>로그인 시간</span>
                            </li>
                            
                            {memberList.loading ? (
                              <li className="empty"> loading...</li>
                            ) : memberList.data && memberList.data.data && memberList.data.data.length > 0 ? ( 
                            memberList.data.data && memberList.data.data.map((item: any, idx: number) => 
                            <li className="tbody">
                                <span className='col-td'>{
                                    // memberList.data.meta.totalCnt -
                                    // (memberList.data.meta.currentPageNo - 1) * memberList.data.meta.pageSize - idx
                                    idx + 1
                                }</span>
                                <span className='col-td'>{item.userId}</span>
                                <span className='col-td'>{item.ipAddr}</span>
                                <span className='col-td'>{new Date(item.loginDt!).toLocaleString()}</span>
                            </li>)
                            ) : (<li className="empty"> 접속 기록이 없습니다. </li>)

                        }
                        </ul>
                        { memberList.data && memberList.data.meta  ? ( 
                            <Pagination key="Pagination" pageingDto={memberList.data.meta} onClick={(page) => handleSearch(page)} />
                        ) : (<span></span>) }
                    </div>
                </div>
                
            </div>
            

        </MypageLayout>
    );
};

export default MypageMemberManage;

import { createReducer } from 'typesafe-actions';
import { AuthState } from './types';
import { lockLoginAsync, loginAsync, logoutAsync, otpAsync, postFindUserIdAsync } from './actions';
import { AnyAction } from 'redux';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: AuthState = {
  data: asyncState.initial(),
  locktime: asyncState.initial(),
  step: 0,
};

const authReducer = createReducer<AuthState, AnyAction>(initialState)
.handleAction(
  transformToArray(otpAsync),
  createAsyncReducer(otpAsync, 'data')
).handleAction(
  transformToArray(loginAsync),
  createAsyncReducer(loginAsync, 'data')
).handleAction(
  transformToArray(logoutAsync),
  createAsyncReducer(logoutAsync, 'data')
)
.handleAction(logoutAsync.success, () => initialState)
.handleAction(
  transformToArray(postFindUserIdAsync),
  createAsyncReducer(postFindUserIdAsync, 'data')
).handleAction(
  transformToArray(lockLoginAsync),
  createAsyncReducer(lockLoginAsync, 'locktime')
);

// .handleAction(lockLoginAsync.success, (state, action) => ({
//   ...state,
//   locktime: {
//     ...state.locktime,
//     [action.payload!.userId]: action.payload!.lockUntil, // 개별 userId별 lockUntil 업데이트
//   },
// }))
// .handleAction(lockLoginAsync.failure, (state, action) => ({
//   ...state,
//   error: action.payload,
// }));


export default authReducer;
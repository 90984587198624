import React, { ChangeEvent, FC, useState } from 'react';
import '../../styles/components/button.scss';
import '../../styles/components/input.scss';
import './TermsAgreement.scss';

interface TermsAgreementProps {
    nextStep: () => void;
    prevStep: () => void;
}

const TermsAgreement: FC<TermsAgreementProps> = ({ nextStep, prevStep }) => {
    const [allChecked, setAllChecked] = useState(false);
    const [terms, setTerms] = useState({
        serviceTerms: false,
        privacyPolicy: false,
    });

    const handleAllCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setAllChecked(checked);
        setTerms({
            serviceTerms: checked,
            privacyPolicy: checked,
        });
    };

    const handleTermChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const isChecked = value === 'true';

        setTerms(prevState => ({
            ...prevState,
            [name]: isChecked,
        }));

        if (!isChecked) {
            setAllChecked(false);
        } else if (terms.serviceTerms && terms.privacyPolicy) {
            setAllChecked(true);
        }
    };

    const handleTermsAgreementClick = () => {
        if (terms.serviceTerms && terms.privacyPolicy) {
            nextStep();
        } else {
            alert('모든 필수 항목에 동의하셔야 합니다.');
        }
    };

    return (
        <div className="regi-terms">
            <div className="row">
                <div className="container">
                    <div className="terms-easy">
                        <div className='terms-allcheck'>
                            <input
                                type="checkbox"
                                id="allcheck"
                                checked={allChecked}
                                onChange={handleAllCheckedChange}
                            />
                            <label htmlFor="allcheck">모두 동의합니다.</label>
                        </div>
                        <p>
                            이용약관, 회원정보 수집 및 이용 동의, 홍보를 위한 정보수집 동의, 광고성 정보 수신 동의 항목에 대해 모두 동의합니다.<br />
                            각 사항에 대한 동의 여부를 개별적으로 선택하실 수 있으며, 선택 동의 사항에 대한 동의를 거부하여도 서비스를 이용하실 수 있습니다.
                        </p>
                    </div>

                    <div className='terms-consent'>
                        <div className='rows'>
                            <div className="consent-title">
                                <em className='color-red'>[필수]</em> 서비스 약관동의
                            </div>
                            <div className="consent-con">
                                <h3>제1조 목적</h3>
                                <p>본 약관은 공공SaaS(이하 ‘운영기관’이라 한다)에서 운영하는 공공SaaS 서비스를 이용함에 있어 이용조건 및 절차, 운영기관과 이용자의 권리·의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
                                <h3>제2조 용어의 정의</h3>
                                <ul>
                                    <li>
                                        본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에서 정의하지 않은 것은 관련 법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에 따른다.
                                        <ol>
                                            <li>① 공유서비스 : 공유서비스란 인공지능 서비스에 활용할 수 있는 데이터, SW, 컴퓨팅 자원 등 우수한 정보자원을 공공 및 민간, 법인 및 개인이 원활하게 활용할 수 있도록 다양한 형식과 방식으로 제공하는 것을 말한다. </li>
                                            <li>② 제공기관 : 제공기관은 기관이 보유하고 있는 우수한 정보자원을 지정된 관리시스템을 통해 활용 가능한 공유서비스의 방식으로 개방, 제공하는 행정기관, 공공기관 및 민간 개인과 법인 등을 말한다. </li>
                                            <li>③ 활용기관 : 활용기관은 제공된 공유서비스를 직접 이용하거나 새로운 창작물의 일부로 재가공하여 배포하는 행정기관 등과 개인 및 법인을 말한다.  </li>
                                            <li>④ 행정기관 등 : 행정기관 및 공공기관을 행정기관 등 이라 말한다.</li>
                                            <li>⑤ 회원 : 공공SaaS에 기관 및 기업, 개인 정보를 제공하여 제공기관 또는 활용기관으로 등록한 자로서  공공SaaS에서 제공하는 서비스를 이용할 수 있는 자. </li>
                                            <li>⑥ 비밀번호 : 이용자와 회원ID가 일치하는지 확인하고 통신상의 자신의 비밀 보호를 위하여 이용자 자신이 선정한 문자와 숫자의 조합.</li>
                                        </ol>
                                    </li>
                                </ul>
                                <h3>제3조 약관의 효력과 변경</h3>
                                <p>
                                    <ul>
                                        <li>1. 공공SaaS는 귀하가 본 약관 내용에 동의하는 경우, 공공SaaS의 서비스 제공 행위 및 귀하의 서비스 사용 행위에 본 약관이 우선적으로 적용됩니다.</li>
                                        <li>2. 운영기관은 본 약관을 약관법 등 관련 법령에 위배되지 않는 범위 안에서 사전 고지 없이 변경할 수 있고, 변경된 약관은 공공SaaS 내에 공지와 동시에 그 효력이 발생됩니다. 회원이 변경된 약관에 동의하지 않는
                                            경우, 회원은 본인의 회원등록을 취소(회원탈퇴)할 수 있으며 계속 사용하는 경우는 약관 변경에 대한 동의로 간주됩니다.</li>
                                    </ul>
                                </p>
                                <h3>제4조 약관 외 준칙</h3>
                                <p>본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신에 관한 심의규정, 저작권법 및 기타 관련 법령의 규정에 의합니다.</p>
                                <h3>제5조 이용 계약의 성립 등</h3>
                                <ul>
                                    <li>1. 이용 계약은 법인 및 개인 신청자가 온라인으로 공공SaaS에서 제공하는 소정의 회원가입 신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.</li>
                                    <li>2. 행정기관 등은 법인 회원으로 가입한 이후에 공유서비스 제공기관으로 전환가입이 가능하며, 제공가입 후에는 공유서비스를 등록할 수 있는 자격이 부여됩니다.</li>
                                    <li>3. 공공SaaS는 다음 각 호에 해당하는 이용계약에 대하여는 회원 가입을 취소할 수 있습니다.</li>
                                    <ol>
                                        <li>① 다른 사람의 명의를 사용하여 신청하였을 때</li>
                                        <li>② 회원 가입 신청서의 내용을 허위로 기재하였을 경우</li>
                                        <li>③ 공공SaaS을 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우</li>
                                        <li>④ 기타 운영기관에서 정한 제공기관 및 활용기관에 대한 공유정책 및 업무처리 기준에 위배된 경우.</li>
                                    </ol>
                                </ul>
                                <h3>제6조 회원 정보 사용에 대한 동의</h3>
                                <ul>
                                    <li>1. 회원의 개인정보는 개인정보법에 의해 보호됩니다.</li>
                                    <li>2. 회원의 개인정보의 보유 기간은 2년이며, 회원 정보 사용에 대해 거부시 서비스 이용에 제한됩니다.</li>
                                    <li>3. 공공SaaS의 회원정보는 다음과 같이 사용, 관리, 보호됩니다.</li>
                                    <ol>
                                        <li>① 개인정보의 사용 : 공공SaaS는 서비스 제공과 관련해서 수집된 회원의 신상정보를 본인의 승낙없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있
                                            는 경우, 범죄에 대한 수사상의 목적이 있거나 방송통신심의위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 귀하가 공공SaaS에 제공한 개인정보를 스스로 공개한
                                            경우에는 그러하지 않습니다. 또한 귀하의 개인정보는 서비스 가입, 이용 및 운영에 사용됩니다.</li>
                                        <li>② 개인정보의 관리 : 공공SaaS는 아이디, 비밀번호, 소속기관, 이메일을 보유하며 귀하는 개인정보의 보호 및 관리를 위하여 서비스의 개인정보관리에서 수시로 귀하의 개인정보(비밀번호, 이메일)를 수정할 수 있으며, 귀하의 전체 개인정보를 삭제할 수 있습니다.</li>
                                        <li>③ 개인정보의 보호 : 귀하의 개인정보는 오직 귀하만이 열람/수정/삭제 할 수 있으며, 이는 전적으로 귀하의 ID와 비밀번호에 의해 관리되고 있습니다. 따라서 타인에게 본인의 ID와 비밀번호를 알려주어서
                                            는 안되며, 작업 종료시에는 반드시 로그아웃 해 주시기 바랍니다.</li>
                                    </ol>
                                    <li>3. 회원이 본 약관에 따라 이용신청을 하는 것은 공공SaaS 회원가입 신청서에 기재된 회원정보를 ‘개인정보 취급방침’에 따라 수집, 이용하는 것에 동의하는 것으로 간주됩니다.</li>
                                </ul>

                                <h3>제7조 사용자의 정보 보안</h3>
                                <ul>
                                    <li>1. 가입 신청자가 공공SaaS 회원가입 절차를 완료하는 순간부터 회원은 입력한 정보의 비밀을 유지할 책임이 있으며, 회원의 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.</li>
                                    <li>2. ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, 회원의 ID나 비밀번호가 부정하게 사용 되었다는 사실을 발견한 경우에는 즉시 운영기관에 신고하여야 합니다. 신고를 하지 않음으로 인한 모든 책임
                                        은 회원 본인에게 있습니다.</li>
                                    <li>3. 회원은 공공SaaS 사용 종료 시마다 정확히 접속을 종료해야 하며, 정확히 종료하지 아니함으로써 제3자가 귀하에 관한 정보를 이용하게 되는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 운영기관은 책임
                                        을 부담하지 아니합니다.</li>
                                </ul>
                                <h3>제8조 포털 이용 시간</h3>
                                <ul>
                                    <li>1. 당 사이트는 제6조의 규정에 의한 이용신청고객에 대하여 서비스 이용을 승낙합니다.</li>
                                    <li>2. 당 사이트는 아래사항에 해당하는 경우에 대해서 승낙하지 아니 합니다. - 이용계약 신청서의 내용을 허위로 기재한 경우 - 기타 규정한 제반사항을 위반하며 신청하는 경우</li>
                                </ul>
                                <h3>제9조 포털의 중지 및 정보의 저장과 사용</h3>
                                <ul>
                                    <li>1. 공공SaaS에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 공공SaaS의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송
                                        되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 운영기관은 관련 책임을 부담하지 아니합니다.</li>
                                    <li>2. 공공SaaS의 정상적인 서비스 제공이 불가하여 일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 1주일 전의 고지 후 서비스를 중지할 수 있으며, 이 기간 동안 귀하가 고지내용을 인지하지 못한 데 대하
                                        여 운영기관은 책임을 부담하지 아니합니다. 부득이한 사정이 있을 경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메
                                        시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 운영기관은 책임을 부담하지 아니합니다.</li>
                                    <li>3. 공공SaaS의 사정으로 서비스를 영구적으로 중단하여야 할 경우 제 2 항에 의거합니다. 다만, 이 경우 사전 고지기간은 1개월로 합니다.</li>
                                    <li>4. 공공SaaS는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 귀하 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.</li>
                                    <li>5. 공공SaaS는 이용자가 본 약관의 내용에 위배되는 행동을 한 경우, 임의적인 회원의 서비스 이용 중지시(회원등록 말소시) 회원에게 이 사실을 통지하고, 회원으로 하여금 소명할 수 있는 기회를 부여하고 소명기
                                        간(30일 이상)을 추가 표기합니다.</li>
                                </ul>
                                <h3>제10조 서비스의 변경 및 해지</h3>
                                <ul>
                                    <li>1. 공공SaaS는 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않으며, 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여
                                        는 책임을 지지 않습니다.</li>
                                    <li>2. 공공SaaS는 서비스 이용과 관련하여 회원에게 발생한 손해 중 회원의 고의, 과실에 의한 손해에 대하여 책임을 부담하지 아니합니다.</li>
                                </ul>
                                <h3>제11조 정보 제공 및 홍보물 게재</h3>
                                <ul>
                                    <li>1. 공공SaaS는 서비스를 운영함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 회원에게 제공할 수 있습니다.</li>
                                    <li>2. 공공SaaS는 서비스에 적절하다고 판단되거나 활용 가능성 있는 홍보물을 게재할 수 있습니다.</li>
                                </ul>
                                <h3>제12조 게시물의 저작권</h3>
                                <ul>
                                    <li>1. 제공기관이 게시한 게시물의 내용에 대한 권리는 기본적으로 제공기관 본인에게 있습니다.</li>
                                    <li>2. 공공SaaS는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.</li>
                                    <ol>
                                        <li>① 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</li>
                                        <li>② 다른 회원 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</li>
                                        <li>③ 공공질서 및 미풍양속에 위반되는 내용인 경우</li>
                                        <li>④ 범죄적 행위에 결부된다고 인정되는 내용일 경우</li>
                                        <li>⑤ 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
                                        <li>⑥ 기타 관계 법령에 위배되는 경우</li>
                                    </ol>
                                    <li>3. 제공기관의 게시물이 타인의 저작권을 비롯한 기타 타인의 권리를 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 제공기관이 부담하여야 합니다.</li>
                                </ul>
                                <h3>제13조 사용자의 행동 규범 및 서비스의 이용 제한</h3>
                                <ul>
                                    <li>1. 회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 그러하다고 의심할 만한 합리적인 사유가 발생할 경우 공공SaaS는 회원의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이
                                        익에 대해 책임을 부담하지 아니합니다.</li>
                                    <li>2. 회원이 공공SaaS의 서비스를 통해 게시, 전송, 입수하였거나 전자메일 기타 다른 수단에 의하여 게시, 전송 또는 입수한 모든 형태의 정보에 대하여는 회원이 모든 책임을 부담하며 공공SaaS는 어떠한 책임도 부
                                        담하지 아니합니다.</li>
                                    <li>3. 공공SaaS는 당 사이트가 제공한 서비스가 아닌 가입자 또는 기타 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여 보장하지 않습니다. 따라서 공공SaaS는 회원이 위 내용을 이용함으로
                                        인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니합니다.</li>
                                    <li>4. 회원은 공공SaaS에서 다운로드 받은 데이터 내에 개인정보 또는 개인정보로 우려되는 정보가 포함된 것을 발견한 경우 즉시 공공SaaS 운영자에 신고하고 해당 데이터셋은 삭제를 하여야 합니다.</li>
                                    <li>5. 회원은 공공SaaS로부터 제공받은 비식별 정보를 인공지능 서비스 개발 등의 목적으로 안전하게 이용하여야 하며, 이를 이용해서 개인을 재식별하기 위한 어떠한 행위도 하여서는 안됩니다.</li>
                                    <li>6. 회원은 본 서비스를 통하여 다음과 같은 행동을 포함하여 서비스 운영정책에서 금지하는 행위를 하지 않는 것에 동의합니다.</li>
                                    <ol>
                                        <li>① 타인의 아이디(ID)와 비밀번호를 도용하는 행위</li>
                                        <li>② 저속, 음란, 모욕적, 위협적이거나 타인의 프라이버시를 침해할 수 있는 내용을 전송, 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</li>
                                        <li>③ 서비스를 통하여 전송된 내용의 출처를 위장하는 행위</li>
                                        <li>④ 법률, 계약에 의하여 이용할 수 없는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</li>
                                        <li>⑤ 타인의 특허, 상표, 영업비밀, 저작권, 기타 지식재산권을 침해하는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</li>
                                        <li>⑥ 운영기관의 승인을 받지 아니한 광고, 판촉물, 정크메일, 스팸, 행운의 편지, 피라미드 조직 기타 다른 형태의 권유를 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</li>
                                        <li>⑦ 의도적으로 바이러스 프로그램 등 파괴적이거나 시스템에 손상을 줄 수 있는 아이템을 배포하는 행위</li>
                                        <li>⑧ 다른 사용자의 개인정보를 수집 또는 저장하는 행위</li>
                                        <li>⑨ 다른 사용자의 공공SaaS 서비스 이용을 방해하는 행위</li>
                                    </ol>
                                    <li>7. 공공SaaS는 회원이 본 약관을 위배했다고 판단되면 서비스와 관련된 모든 정보를 회원의 동의 없이 삭제할 수 있습니다.</li>
                                </ul>
                                <h3>제14조 포털의 의무</h3>
                                <ul>
                                    <li>1. 공공SaaS는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적, 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.</li>
                                    <li>2. 공공SaaS는 회원의 개인 신상 정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다. 다만, 전기통신관련법령 등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.</li>
                                    <li>3. 공공SaaS는 이용자가 안전하게 당 사이트 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안시스템을 갖추어야 합니다.</li>
                                    <li>4. 공공SaaS는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.</li>
                                </ul>
                                <h3>제15조 회원의 의무</h3>
                                <ul>
                                    <li>1. 회원 가입시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 회원 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
                                    <li>2. 회원은 공공SaaS의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</li>
                                    <li>3. 회원은 일부 개인정보를 포함한 서비스 중 사전 승낙이 필요함을 별도로 명시한 경우 사전 승낙 없이 공공SaaS의 서비스를 이용하여 얻은 정보를 복사, 복제, 변경, 번역, 출판·방송 기타의 방법으로 사용하거나 이
                                        를 타인에게 제공할 수 없습니다.</li>
                                    <li>4. 회원은 공공SaaS의 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.</li>
                                    <ol>
                                        <li>① 다른 회원의 ID를 부정 사용하는 행위</li>
                                        <li>② 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</li>
                                        <li>③ 선량한 풍속, 기타 사회질서를 해하는 행위</li>
                                        <li>④ 타인의 명예를 훼손하거나 모욕하는 행위</li>
                                        <li>⑤ 타인의 지식재산권 등의 권리를 침해하는 행위</li>
                                        <li>⑥ 해킹행위 또는 컴퓨터바이러스의 유포행위</li>
                                        <li>⑦ 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위</li>
                                        <li>⑧ 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</li>
                                        <li>⑨ 공공SaaS에 게시된 정보의 변경</li>
                                    </ol>
                                </ul>
                                <h3>제16조 포털의 소유권</h3>
                                <ul>
                                    <li>1. 공공SaaS가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지식재산권 및 기타 권리는 운영기관(및 공공SaaS 서비스 제공과 관련하여 운영기관과
                                        계약을 체결한 기관)에 소유권이 있습니다.</li>
                                    <li>2. 귀하는 공공SaaS에서 명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없
                                        으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.</li>
                                </ul>
                                <h3>제17조 양도 금지</h3>
                                <ul>
                                    <li>회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다. 단, 법인회원의 대표담당자 변경은 예외로 합니다.</li>
                                </ul>
                                <h3>제18조 손해 조항</h3>
                                <ul>
                                    <li>공공SaaS는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 공공SaaS가 고의로 행한 범죄행위를 제외하고 이에 대하여 책임을 부담하지 아니합니다.</li>
                                </ul>
                                <h3>제19조 면책 조항</h3>
                                <ul>
                                    <li>1. 공공SaaS는 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며 회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. 공공SaaS는 어떠한 경우라도 회원
                                        이 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.</li>
                                    <li>2. 공공SaaS는 회원 간 또는 회원과 제3자 간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임
                                        을 부담하지 않습니다.</li>
                                </ul>
                                <h3>제20조 관할 법원</h3>
                                <ul>
                                    <li>본 서비스 이용과 관련하여 발생한 분쟁에 대해 소송이 제기될 경우 서울중앙지방법원을 전속적 관할 법원으로 합니다.</li>
                                    <ol>
                                        <li>① (시행일) 본 약관은 2025년 1월 1일부터 시행됩니다.</li>
                                    </ol>
                                </ul>
                            </div>
                            {/* <button type='button' className='button button--outline button--sm views-detail'>약관읽기</button> */}
                            <div className='consent-actions'>
                                <div className="consent-desc">
                                    서비스 약관동의에 대한 안내 사항을 읽고 동의합니다.
                                </div>
                                <div className="consent-check">
                                    <label className='label-radio'>
                                        <input
                                            type="radio"
                                            name="serviceTerms"
                                            value="false"
                                            checked={!terms.serviceTerms}
                                            onChange={handleTermChange}
                                        /><i></i>동의하지 않음
                                    </label>
                                    <label className='label-radio'>
                                        <input
                                            type="radio"
                                            name="serviceTerms"
                                            value="true"
                                            checked={terms.serviceTerms}
                                            onChange={handleTermChange}
                                        /><i></i> 동의함
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='rows'>
                            <div className="consent-title">
                                <em className='color-red'>[필수]</em> 개인정보 처리방침 동의
                            </div>
                            <div className="consent-con">
                                <section className="cont-agg-all_boxtext">"공공SaaS"에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하여, 이를 위반시 정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.</section>
                                <div className="table mb30">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th className='tac' colSpan={4}>
                                                    목차
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    제1조
                                                </th>
                                                <td>
                                                    개인정보의 처리 목적
                                                </td>
                                                <th>
                                                    제8조
                                                </th>
                                                <td>
                                                    개인정보의 파기
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    제2조
                                                </th>
                                                <td>
                                                    개인정보의 처리 및 보유기간
                                                </td>
                                                <th>
                                                    제9조
                                                </th>
                                                <td>
                                                    개인정보의 안전성 확보조치
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    제3조
                                                </th>
                                                <td>
                                                    개인정보의 수집 이용저장
                                                </td>
                                                <th>
                                                    제10조
                                                </th>
                                                <td>
                                                    개인정보 자동 수집 장치의 설치&middot;운영 및 거부에 관한 상황
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    제4조
                                                </th>
                                                <td>
                                                    개인정보의 제3자 제공
                                                </td>
                                                <th>
                                                    제11조
                                                </th>
                                                <td>
                                                    개인정보 보호책임자
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    제5조
                                                </th>
                                                <td>
                                                    개인정보처리의 위탁
                                                </td>
                                                <th>
                                                    제12조
                                                </th>
                                                <td>
                                                    개인정보 열람청구
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    제6조
                                                </th>
                                                <td>
                                                    정보주체와 법정대리인이 권리&middot;의무 및 행사방법
                                                </td>
                                                <th>
                                                    제13조
                                                </th>
                                                <td>
                                                    권익침해 구제방법
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    제7조
                                                </th>
                                                <td>
                                                    처리하는&nbsp; 개인정보 항목
                                                </td>
                                                <th>
                                                    제14조
                                                </th>
                                                <td>
                                                    개인정보 처리방침 변경
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h3>제1조 개인정보의 처리 목적</h3>
                                <ul>
                                    <li>
                                        1. 공공SaaS는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고있는 개인정보는 다음의 목적 이외 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조(개인정보의 목적 외 이
                                        용·제공제한)에 따라 별도의 동의를 받는 등 필요한 조치를 이행하고 있습니다.
                                        <ol>
                                            <li>
                                                ①  홈페이지 회원 가입 및 관리
                                                <ol>
                                                    <li>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별‧인증, 회원자격 유지‧관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보처리 시 법정 대리인의 동의 여부 확인, 사외보 발송 등을 목적으로 개인정보를 처리합니다.</li>
                                                </ol>
                                            </li>
                                            <li>
                                                ② 민원사무 처리
                                                <ol>
                                                    <li>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락‧통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        2. 공공SaaS가 개인정보 보호법 제32조(개인정보파일의 등록 및 공개)에 따라 등록·공개하는 개인정보 파일의 처리 목적은 다음과 같습니다.
                                    </li>
                                    <div className="table mb30">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='tac'>
                                                        순번
                                                    </th>
                                                    <th className='tac'>
                                                        개인정보 파일 명칭
                                                    </th>
                                                    <th className='tac'>
                                                        운영근거/처리목적
                                                    </th>
                                                    <th className='tac'>
                                                        파일에 기록되는 항목
                                                    </th>
                                                    <th className='tac'>
                                                        보유기간
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        1
                                                    </td>
                                                    <td>
                                                        영상촬영 서비스
                                                    </td>
                                                    <td>
                                                        •공간정보의 구축 및 관리 등에 관한 법률 24조 및 시행령 104조의 2 <br />
                                                        •회원가입자 정보관리
                                                    </td>
                                                    <td>
                                                        •필수: 아이디, 비밀번호, 이름 <br />
                                                        •선택: 주민등록번호, 주소, 휴대전화번호 토지소재지, 이메일
                                                    </td>
                                                    <td>
                                                        회원탈퇴 시, 2년
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        2
                                                    </td>
                                                    <td>
                                                        AI 데이터 생성관리 서비스

                                                    </td>
                                                    <td>
                                                        •공간정보의 구축 및 관리 등에 관한 법률 24조 및 시행령 104조의 2 <br />
                                                        •지적측량 의뢰고객 관리 및 변경 <br />
                                                        •부가가치세법 제32조 <br />
                                                    </td>
                                                    <td>
                                                        •필수: 성명, 주소, 토지 소재지, 휴대전화번호, 주민(외국인)등록번호, 이메일 <br />
                                                        •선택: 전화번호, 이메일
                                                    </td>
                                                    <td>
                                                        5년
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        3
                                                    </td>
                                                    <td>
                                                        디지털트윈 지도 서비스
                                                    </td>
                                                    <td>
                                                        •민원처리에 관한 법률 제2조2항 및 시행령 제7조 2항 <br />
                                                        •민원신청 고객 정보관리
                                                    </td>
                                                    <td>
                                                        •필수: 성명, 주소 <br />
                                                        •선택: 토지소재지, 전화번호, 휴대전화번호, 이메일
                                                    </td>
                                                    <td>
                                                        10년
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        4
                                                    </td>
                                                    <td>
                                                        영상정보 관리 및 분석 서비스
                                                    </td>
                                                    <td>
                                                        •정보주체의 동의 <br />
                                                        •사외보 발송
                                                    </td>
                                                    <td>
                                                        •필수: 성명, 주소, 휴대전화번호, 이메일 <br />
                                                        •선택: 없음
                                                    </td>
                                                    <td>
                                                        구독해지 시 까지
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <li>
                                        3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.(IP 주소, 서비스 이용기록, 방문기록, 불량 이용기록 등)
                                    </li>
                                </ul>
                                <h3>제2조 개인정보의 처리 및 보유기간</h3>
                                <ul>
                                    <li>
                                        1. 공공SaaS는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
                                    </li>
                                    <li>
                                        2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다

                                        <ol>
                                            <li>
                                                ① 영상촬영 서비스
                                                <ol>
                                                    <li>회원 탈퇴 시까지(2년 경과 시 재동의)</li>
                                                </ol>
                                            </li>
                                            <li>
                                                ②  AI 데이터 생성관리 서비스
                                                <ol>
                                                    <li>지적측량업무 종료 후 5년간 보유</li>
                                                </ol>
                                            </li>
                                            <li>
                                                ③  디지털트윈 지도 서비스
                                                <ol>
                                                    <li>민원사무 처리 후 10년간 보유</li>
                                                </ol>
                                            </li>
                                            <li>
                                                ④ 영상정보 관리 및 분석 서비스
                                                <ol>
                                                    <li>구독 해지 시까지(2년 경과 시 재동의)</li>
                                                </ol>

                                            </li>
                                        </ol>
                                    </li>
                                    <li>3. 인터넷 서비스 이용 과정에서 아래 항목이 자동으로 생성되어 최소 6개월 이상 보유·관리 됩니다.(IP 주소, 서비스 이용 기록, 방문 기록, 불량 이용 기록 등)</li>
                                </ul>
                                <h3>제3조 개인정보의 수집·이용·저장</h3>
                                <ul>
                                    <li>
                                        공공SaaS는 개인정보의 수집 시 개인정보 보호법 제15조 제2항(법령상의무를 준수하기 위하여 불가피한 경우)를 제외 하고는 정보 주체의 동의에 의하여 수집하고 저장은 정부에서 권장하는 암호화를 준수하여 보관
                                        하며 개인정보의 동의 목적 내에서 만 이용합니다.
                                    </li>
                                </ul>
                                <h3>제4조 개인정보의 제3자 제공</h3>
                                <ul>
                                    <li>
                                        1. 공공SaaS는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                                    </li>
                                    <li>2. 공공SaaS는 아래의 경우를 제외하고는 정보주체의 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. </li>
                                    <ol>
                                        <li>① 정보주체로부터 별도의 동의를 받는 경우 </li>
                                        <li>② 법률에 특별한 규정이 있는 경우 </li>
                                        <li>③ 정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우 </li>
                                        <li>④ 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 개인정보보호 위원회의 심의·의결을 거친 경우 </li>
                                        <li>⑤ 조약, 그 밖의 국제협정의 이행을 위하여 외국정보 또는 국제기구에 제공하기 위하여 필요한 경우 </li>
                                        <li>⑥ 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우 </li>
                                        <li>⑦ 법원의 재판업무 수행을 위하여 필요한 경우 </li>
                                        <li>⑧ 형 및 감호, 보호처분의 집행을 위하여 필요한 경우 </li>
                                    </ol>
                                    <li>3. 공공SaaS는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다. </li>
                                    <div className="table mb30">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='tac'>
                                                        운영시스템
                                                    </th>
                                                    <th className='tac'>
                                                        제공받는 기관
                                                    </th>
                                                    <th className='tac'>
                                                        제공 대상
                                                    </th>
                                                    <th className='tac'>
                                                        이용 목적
                                                    </th>
                                                    <th className='tac'>
                                                        관계 법령
                                                    </th>
                                                    <th className='tac'>
                                                        제공하는 항목
                                                    </th>
                                                    <th className='tac'>
                                                        제공받는자의 이용기간
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        영상정보 관리 및
                                                        분석 서비스
                                                    </td>
                                                    <td>
                                                        기획재정부
                                                    </td>
                                                    <td>
                                                        지적측량 의뢰고객
                                                    </td>
                                                    <td>
                                                        고객만족도 조사
                                                    </td>
                                                    <td>
                                                        공공기관의 운영에 관한 법률 제13조 2항
                                                    </td>
                                                    <td>
                                                        성명, 주소, 휴대전화번호
                                                    </td>
                                                    <td>
                                                        목적달성 후 파기
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ul>
                                <h3>제5조 개인정보처리의 위탁</h3>
                                <ul>
                                    <li>
                                        1. 공공SaaS는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적‧관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리‧감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                                    </li>
                                    <li>2. 공공SaaS는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</li>
                                    <div className="table mb30">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='tac'>
                                                        운영시스템
                                                    </th>
                                                    <th className='tac'>
                                                        제공받는 기관
                                                    </th>
                                                    <th className='tac'>
                                                        제공 대상
                                                    </th>
                                                    <th className='tac'>
                                                        연락처
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        영상촬영 서비스
                                                    </td>
                                                    <td>
                                                        시스템 전반 유지 관리
                                                    </td>
                                                    <td>
                                                        (주)스패셜티
                                                    </td>
                                                    <td>
                                                        063-228-9650
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={2}>
                                                        AI 데이터 생성관리 서비스
                                                    </td>
                                                    <td>
                                                        시스템 전반 유지 관리
                                                    </td>
                                                    <td>
                                                        ㈜엠브레인리서치
                                                    </td>
                                                    <td>
                                                        02-2015-2400
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        시스템 전반 유지 관리
                                                    </td>
                                                    <td>
                                                        (주)스패셜티
                                                    </td>
                                                    <td>
                                                        063-228-9650
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        디지털트윈 서비스
                                                    </td>
                                                    <td>
                                                        시스템 전반 유지 관리
                                                    </td>
                                                    <td>
                                                        (주)스패셜티
                                                    </td>
                                                    <td>
                                                        063-228-9650
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        디지털트윈 지도 서비스
                                                    </td>
                                                    <td>
                                                        시스템 전반 유지 관리
                                                    </td>
                                                    <td>
                                                        ㈜이팝
                                                    </td>
                                                    <td>
                                                        02-514-7567
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <li>3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다. </li>
                                </ul>
                                <h3>제6조 정보주체와 법정대리인의 권리‧의무 및 행사방법</h3>
                                <ul>
                                    <li>
                                        1. 정보주체는 공공SaaS에 대해 언제든지 개인정보 열람‧정정‧삭제‧처리정지 요구 등의 권리를 행사할 수 있습니다.
                                    </li>
                                    <li>2. 제1항에 대한 권리 행사는 개인정보 처리 방법에 관한 고시 별지 제8호 서식에 따라 작성 후 서면, 전자우편, FAX 등을 통하여 하실 수 있으며 공공SaaS는 이에 대해 지체 없이 조치하겠습니다. </li>
                                    <li>3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리방법에 관한 고시 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. </li>
                                    <li>4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다. </li>
                                    <li>5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. </li>
                                    <li>6. 공공SaaS는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다. </li>
                                </ul>
                                <h3>제7조 개인정보의 처리 및 보유기간</h3>
                                <ul>
                                    <li>
                                        공공SaaS는 다음의 개인정보 항목을 처리하고 있습니다.
                                    </li>
                                    <li>1. 지적측량 바로처리 온라인 회원가입 및 관리</li>
                                    <ol>
                                        <li>• 필수 항목 : 성명, 아이디, 비밀번호 </li>
                                        <li>• 선택 항목 : 주민등록번호, 주소, 휴대전화번호, 토지소재지, 이메일 </li>
                                    </ol>
                                    <li>2. 지적측량 의뢰</li>
                                    <ol>
                                        <li>• 필수 항목 : 성명, 주민(외국인)등록번호, 주소, 휴대전화번호, 토지소재지, 이메일 </li>
                                        <li>• 선택 항목 : 전화번호, 이메일 </li>
                                    </ol>
                                    <li>3. 민원 신청</li>
                                    <ol>
                                        <li>• 필수 항목 : 성명, 주소 </li>
                                        <li>• 선택 항목 : 토지소재지, 전화번호, 토지소재지, 이메일 </li>
                                    </ol>
                                    <li>4. 사외보 구독 신청</li>
                                    <ol>
                                        <li>• 필수 항목 : 성명, 주소, 휴대전화번호, 이메일 </li>
                                        <li>• 선택 항목 : 없음 </li>
                                    </ol>
                                    <li>5. 공간정보아카데미 회원가입 및 관리</li>
                                    <ol>
                                        <li>• 필수 항목 : 성명, 아이디, 비밀번호, 휴대전화번호, 이메일 </li>
                                        <li>• 선택 항목 : 회사명, 사업자등록번호, 대표자명, 협약기업유무, 협약기업명 </li>
                                    </ol>
                                </ul>
                                <h3>제8조 개인정보의 파기</h3>
                                <ul>
                                    <li>
                                        1. 공공SaaS는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
                                    </li>
                                    <li>2. 개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
                                    <ol>
                                        <li>
                                            ① 파기 절차
                                            <ol>
                                                <li>공공SaaS는 파기하여야 하는 개인정보에 대해 개인정보 파기계획을 수립하여 파기합니다. 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
                                            </ol>
                                        </li>
                                        <li>
                                            ② 파기 방법
                                            <ol>
                                                <li>공공SaaS는 전자적 파일 형태로 기록‧저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 이용하여 파기하며, 종이 문서에 기록‧저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </ul>
                                <h3>제9조 개인정보의 안전성 확보조치</h3>
                                <ul>
                                    <li>
                                        공공SaaS는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                                    </li>
                                    <li>1. 관리적 조치 <br />
                                        <ol>
                                            <li>내부관리계획 수립·시행, 정기적 직원 교육 등</li>
                                        </ol>
                                    </li>
                                    <li>
                                        2. 기술적 조치 <br />
                                        <ol>
                                            <li>개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</li>
                                        </ol>
                                    </li>
                                    <li>3. 물리적 조치
                                        <ol>
                                            <li>전산실, 자료보관실 등의 접근통제</li>
                                        </ol>
                                    </li>
                                </ul>
                                <h3>제10조 개인정보 자동 수집 장치의 설치‧운영 및 거부에 관한 사항</h3>
                                <ul>
                                    <li>
                                        1. 공공SaaS는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
                                    </li>
                                    <li>2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</li>
                                    <ol>
                                        <li>
                                            ① 쿠키의 사용목적
                                            <ol>
                                                <li>이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
                                            </ol>
                                        </li>
                                        <li>
                                            ② 쿠키의 설치·운영 및 거부
                                            <ol>
                                                <li>웹브라우저 상단의 도구{'>'}인터넷 옵션{'>'}개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>
                                            </ol>
                                        </li>
                                        <li>③ 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다. </li>
                                    </ol>
                                    <li>3. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에서 정의하지 않은 것은 관련 법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에 따른다. </li>
                                </ul>
                                <h3>제11조 개인정보 보호책임자</h3>
                                <ul>
                                    <li>
                                        1. 공사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                                        <div className="table mb30">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th className='tac' colSpan={2}>
                                                            개인정보 보호 책임자
                                                        </th>
                                                        <th className='tac' colSpan={2}>
                                                            개인정보 보호 담당자
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            담당부서
                                                        </th>
                                                        <td>
                                                            정보전략실
                                                        </td>
                                                        <th>
                                                            담당부서
                                                        </th>
                                                        <td>
                                                            정보보안부
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            직책
                                                        </th>
                                                        <td>
                                                            실장
                                                        </td>
                                                        <th>
                                                            직책
                                                        </th>
                                                        <td>
                                                            개인정보보호 담당
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            성명
                                                        </th>
                                                        <td>
                                                            이장희
                                                        </td>
                                                        <th>
                                                            063-228-9650
                                                        </th>
                                                        <td>
                                                            강경성
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            전화
                                                        </th>
                                                        <td colSpan={3}>
                                                            063-710-0400, 0444
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            이메일
                                                        </th>
                                                        <td colSpan={3}>
                                                            privacy@lx.or.kr
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            FAX
                                                        </th>
                                                        <td colSpan={3}>
                                                            063-710-0470
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            주소
                                                        </th>
                                                        <td colSpan={3}>
                                                            54870 전라북도 전주시 덕진구 기지로 120(중동) 공공SaaS
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </li>
                                    <li>2. 공사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 공사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. </li>
                                </ul>
                                <h3>제12조 개인정보 열람청구</h3>
                                <ul>
                                    <li>
                                        1. 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 공공SaaS는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                                    </li>
                                    <div className="table mb30">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='tac' colSpan={2}>
                                                        개인정보 보호 책임자
                                                    </th>
                                                    <th className='tac' colSpan={2}>
                                                        개인정보 보호 담당자
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        담당부서
                                                    </th>
                                                    <td colSpan={3}>
                                                        정보보안부
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        직책
                                                    </th>
                                                    <td colSpan={3}>
                                                        개인정보보호 담당 강경성
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        성명
                                                    </th>
                                                    <td colSpan={3}>
                                                        Tel)063-710-0444 <br />
                                                        Fax)063-710-0470 <br />
                                                        privacy@lx.or.kr <br />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <li>2. 정보주체께서는 제1항의 열람청구 접수·처리부서 이외에,개인정보보호위원회의 ‘개인정보보호 포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실 수 있습니다. </li>
                                </ul>
                                <h3>제13조 권익침해 구제방법</h3>
                                <ul>
                                    <li>
                                        정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다
                                    </li>
                                    <li>! 아래의 기관은 공공SaaS와는 별개의 기관으로서, 공공SaaS의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다. </li>
                                    <div className="table mb30">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='tac' colSpan={6}>
                                                        개인정보 침해신고센터(한국인터넷진흥원 운영)
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        소관업무
                                                    </th>
                                                    <td colSpan={5}>
                                                        개인정보 침해사실 신고, 상담 신청
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        홈페이지
                                                    </th>
                                                    <td colSpan={5}>
                                                        privacy.kisa.or.kr
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        전화
                                                    </th>
                                                    <td colSpan={5}>
                                                        (국번없이) 118
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        주소
                                                    </th>
                                                    <td colSpan={5}>
                                                        우)58324 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="table mb30">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='tac' colSpan={6}>
                                                        개인정보 분쟁조정위원회
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        소관업무
                                                    </th>
                                                    <td colSpan={5}>
                                                        개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        홈페이지
                                                    </th>
                                                    <td colSpan={5}>
                                                        www.kopico.go.kr
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        전화
                                                    </th>
                                                    <td colSpan={5}>
                                                        (국번없이) 1833-6972
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        주소
                                                    </th>
                                                    <td colSpan={5}>
                                                        우)03171 서울특별시 종로구 세종대로 209 정부서울청사 12층
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <li>! 대검찰청 사이버수사과 : (국번없이) 1301 (www.spo.go.kr) </li>
                                    <li>! 경찰청 사이버범죄 신고시스템(ECRM) : (국번없이)182 (http://ecrm.police.go.kr) </li>
                                </ul>
                                <h3>제14조 개인정보 처리방침 변경</h3>
                                <ul>
                                    <li>1. 정보주체는 아래의 기관에 대해 개인정보이 개인정보 처리방침은 2023년 5월 29일부터 적용됩니다.</li>
                                    <li>2. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</li>
                                </ul>
                            </div>
                            <div className='consent-actions'>
                                <div className="consent-desc">
                                    개인정보 처리방침 동의에 대한 안내 사항을 읽고 동의합니다.
                                </div>
                                <div className="consent-check">
                                    <label className='label-radio'>
                                        <input
                                            type="radio"
                                            name="privacyPolicy"
                                            value="false"
                                            checked={!terms.privacyPolicy}
                                            onChange={handleTermChange}
                                        /><i></i>동의하지 않음
                                    </label>
                                    <label className='label-radio'>
                                        <input
                                            type="radio"
                                            name="privacyPolicy"
                                            value="true"
                                            checked={terms.privacyPolicy}
                                            onChange={handleTermChange}
                                        /><i></i> 동의함
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="button-group center">
                        <button type='button' className='button button--md button--primary' onClick={handleTermsAgreementClick}>다음 단계</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TermsAgreement;
